<template>
  <td v-if="ground">
    <div>
      <span :style="[{ backgroundColor: ground.fiveCircleColor }]">{{
        ground.chinese
      }}</span>
      {{ ground.korean }}
      <div class="ten-star">{{ ground.tenStar }}({{ ground.minusPlus }})</div>
    </div>
  </td>
  <td v-else class="block"></td>
</template>

<script>
export default {
  name: "FortuneGround",
  props: {
    ground: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>
.block {
  background-color: #cfd8dc;
  height: 40px; /* 고정된 높이 설정 */
}
.ten-star {
  background-color: #cfd8dc;
  font-size: 13px;
}
</style>
