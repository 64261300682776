<template>
  <div class="auth-page">
    <v-row justify="center" align="center">
      <!-- (1) 최대 너비를 제한한 컨테이너 -->
      <v-container class="auth-container">
        <v-row justify="center" align="center" class="auth-content">
          <!-- 브랜드 / 로고 영역 -->
          <v-col cols="12" class="text-center mb-8">
            <div
              class="font-weight-bold text-h4 mb-4 text-center justify-center d-flex align-center"
            >
              <v-icon
                style="
                  color: #ffd600;
                  font-size: 45px;
                  transform: rotate(90deg);
                  transition: transform 0.3s;
                "
              >
                mdi-all-inclusive-box
              </v-icon>
              <v-toolbar-title>
                <span style="color: #ffd600">에잇콘텐츠</span>
                <!-- <span style="color: gray">Content</span> -->
              </v-toolbar-title>
            </div>
            <div class="text-h6 font-weight-medium mb-2">
              <strong>
                <span style="color: #7b61ff">내 사주</span>
                <span style="color: gray">로 보는</span>
                <span style="color: #7b61ff"> 연애 콘텐츠</span>
              </strong>
            </div>
          </v-col>
          <div>
            <strong style="color: #616161">로그인 후 이용해주세요</strong>
          </div>
          <br />

          <!-- (2) 간편 로그인 -->
          <v-col cols="12" class="text-center mb-6 d-flex justify-center">
            <span class="mb-3" style="color: #616161; font-weight: 600">
              <v-img
                width="200"
                src="@/assets/kakao_login_medium_narrow.png"
                style="cursor: pointer"
                @click="kakaoLogin"
              />
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <!-- 로딩 오버레이 -->
    <v-overlay class="loading-center" v-if="loading" :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<!-- 카카오 로그인 -->
<script
  src="https://t1.kakaocdn.net/kakao_js_sdk/2.7.0/kakao.min.js"
  integrity="sha384-l+xbElFSnPZ2rOaPrU//2FF5B4LB8FiX5q4fXYTlfcG4PGpMkE1vcL7kNXI6Cci0"
  crossorigin="anonymous"
></script>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AuthPage",
  data() {
    return {
      loading: false,
      fortuneContentId: null,
      sexType: null,
      calendarType: null,
      birthday: null,
      time: null,
      memberType: null,
      memberId: null,
      serviceType: null,
    };
  },
  async mounted() {
    // query 파라미터 설정
    this.serviceType = this.$route.query.serviceType;
    this.memberType = this.$route.query.memberType;
    this.memberId = this.$route.query.memberId;
    this.fortuneContentId = this.$route.query.fortuneContentId;
    this.sexType = this.$route.query.sexType;
    this.calendarType = this.$route.query.calendarType;
    this.birthday = this.$route.query.birthday;
    this.time = this.$route.query.time;
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
  },
  methods: {
    kakaoLogin() {
      if (!window.Kakao) {
        console.error("Kakao SDK not loaded");
        return;
      }

      const sessionId = localStorage.getItem("session_id");
      const utmCampaign = localStorage.getItem("utm_campaign");
      const stateObj = {
        serviceType: this.serviceType,
        fortuneContentId: this.fortuneContentId,
        sexType: this.sexType,
        calendarType: this.calendarType,
        birthday: this.birthday,
        time: this.time,
        memberType: this.memberType,
        memberId: this.memberId,
        sessionId: sessionId,
        utmCampaign: utmCampaign,
      };

      const redirectUri = `${process.env.VUE_APP_API_URL}/v1/auth/kakao`;

      // Kakao.Auth.authorize() 호출
      Kakao.Auth.authorize({
        redirectUri: redirectUri,
        state: JSON.stringify(stateObj),
        throughTalk: false,
      });
    },
  },
};
</script>

<style scoped>
/* 페이지 전체를 감싸는 컨테이너에 최대 너비 제한 */
.auth-page {
  width: 100%;
  min-height: 100vh;
  margin-top: 100px;
}

/* 실제 내부 컨테이너에 max-width를 적용하여
   화면이 넓어도 일정 너비로 고정되도록 함 */
.auth-container {
  max-width: 390px; /* PC에서도 400px로 고정 */
  margin: 0 auto; /* 중앙 정렬 */
  margin-top: 60px; /* 상단 여백 자유롭게 조절 */
}

/* 로딩 오버레이 중앙 정렬 */
.loading-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
