<template>
  <div class="free-fortune-content-page" v-if="fortuneContent">
    <v-row justify="center" align="center" class="mt-4" wrap>
      <!-- 모바일에서 전체 너비, 큰 화면에서는 4 열 차지 -->
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="pa-4" elevation="0">
          <!-- 큰 제목: 가운데 정렬 -->
          <v-card-title
            class="page-title text-h5 text-primary font-weight-bold text-center"
          >
            {{ fortuneContent.title }}
          </v-card-title>

          <!-- 부제목: 가운데 정렬 -->
          <v-card-subtitle class="content text-subtitle-1 text-left mb-4">
            {{ fortuneContent.subtitle }}
          </v-card-subtitle>

          <!-- 이미지 섹션 -->
          <div class="text-center">
            <img
              :src="fortuneContent.detailPageImageUrl"
              alt="Main Image"
              width="80%"
            />
          </div>

          <!-- 상품 소개 -->
          <v-card-text
            v-if="fortuneContent.intro"
            class="description text-left"
          >
            <vue-markdown
              class="markdown-description"
              :key="fortuneContent.intro"
            >
              {{ fortuneContent.intro }}
            </vue-markdown>
          </v-card-text>

          <!-- 상품 목차 -->
          <v-card-text
            v-if="fortuneContent.tableOfContents"
            class="description text-left"
          >
            <vue-markdown
              class="markdown-description"
              :key="fortuneContent.tableOfContents"
            >
              {{ fortuneContent.tableOfContents }}
            </vue-markdown>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 하단 고정 버튼 -->
    <v-btn
      class="fixed-bottom free-fortune-btn"
      style="height: 56px"
      large
      @click="openMemberForm"
    >
      무료 분석 시작
    </v-btn>

    <!-- MemberForm 다이얼로그 -->
    <MemberForm v-model="dialog" @freeFortune="handleFreeFortune" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MemberForm from "@/components/fortune-content/MemberForm.vue";
import VueMarkdown from "vue-markdown";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "FreeFortuneContentPage",
  data() {
    return {
      dialog: false, // UserForm 다이얼로그 열기 여부
      fortuneContentId: null,
    };
  },
  created() {
    // 세션 ID 체크 및 생성
    const id = this.$route.params.id;
    this.fortuneContentId = id;
    this.getFortuneContent(id);
    this.checkAndCreateSessionId();
  },
  components: {
    VueMarkdown,
    MemberForm,
  },
  methods: {
    checkAndCreateSessionId() {
      try {
        let sessionId = localStorage.getItem("session_id");
        console.log(sessionId);
        // 세션 ID가 없으면 새로 생성
        if (sessionId === null) {
          sessionId = uuidv4();
          localStorage.setItem("session_id", sessionId);
          console.log("New session created:", sessionId);

          // URL 파라미터에서 UTM 정보 가져오기
          const urlParams = new URLSearchParams(window.location.search);
          const utmSource = urlParams.get("source") || "";
          const utmMedia = urlParams.get("media") || "";
          const utmCampaign = urlParams.get("campaign") || "organic";
          localStorage.setItem("utm_campaign", utmCampaign);

          // 레퍼러 정보 가져오기
          const referer = document.referrer || "";
          // 사용자 에이전트 정보 가져오기
          const userAgent = navigator.userAgent;

          // API 호출
          this.sendEvent({
            sessionId: sessionId,
            eventType: "PAGE_VIEW",
            fortuneContentId: this.fortuneContentId,
            utmSource: utmSource,
            utmMedia: utmMedia,
            utmCampaign: utmCampaign,
            referer: referer,
            userAgent: userAgent,
          });
        }
      } catch (e) {
        console.warn("localStorage is not available:", e);
      }
    },

    openMemberForm() {
      this.dialog = true; // 다이얼로그 열기
    },
    async handleFreeFortune(data) {
      if (!this.isAuthorized) {
        // 쿼리 파라미터로 페이지 이동하기
        if (data.memberType === "existing") {
          this.$router.push({
            name: "AuthPage",
            query: {
              fortuneContentId: this.fortuneContentId,
              memberType: data.memberType,
              memberId: data.memberId,
            },
          });
        } else if (data.memberType === "new") {
          this.$router.push({
            name: "AuthPage",
            query: {
              fortuneContentId: this.fortuneContentId,
              memberType: data.memberType,
              sexType: data.sexType,
              calendarType: data.calendarType,
              birthday: data.birthday,
              time: data.time,
            },
          });
        }
      } else {
        if (data.memberType === "existing") {
          this.$router.push({
            name: "FreeFortuneResultPage",
            query: {
              fortuneContentId: this.fortuneContentId,
              memberType: data.memberType,
              memberId: data.memberId,
            },
          });
        } else if (data.memberType === "new") {
          this.$router.push({
            name: "FreeFortuneResultPage",
            query: {
              fortuneContentId: this.fortuneContentId,
              memberType: data.memberType,
              sexType: data.sexType,
              calendarType: data.calendarType,
              birthday: data.birthday,
              time: data.time,
            },
          });
        }
      }
    },
    ...mapActions(["getFortuneContent", "sendEvent"]),
  },
  computed: {
    ...mapState(["fortuneContent"]),
    ...mapGetters(["isAuthorized"]),
  },
};
</script>

<style scoped>
.free-fortune-content-page {
  padding-top: 64px;
  padding-bottom: 80px; /* 버튼이 컨텐츠를 가리지 않도록 하단에 추가 여백 */
}

.free-fortune-btn {
  width: 100%;
  font-weight: bold;
  font-size: 15px; /* 텍스트 크기 */
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: #ffd600 !important; /* 버튼 배경색 */
  color: #7b61ff !important; /* 버튼 글자색 */
  z-index: 9999; /* 패널 및 기타 요소보다 위에 오도록 */
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  color: black; /* 제목 색상 */
  /* color: #7b61ff; */
  text-align: center;
  margin-top: -30px;
  margin-bottom: 10px;
  /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); */
}

.content {
  font-size: 18px;
  font-weight: 500;
  color: #333333; /* 부제목 색상 */
  text-align: center;
  opacity: 0.8; /* 약간 투명하게 */
}

::v-deep .markdown-content {
  padding-left: 40px;
}

::v-deep .markdown-content ol {
  padding-left: 40px;
  list-style-position: inside;
}

::v-deep .markdown-content li {
  text-align: left;
  margin-bottom: 10px;
}

::v-deep .markdown-content span {
  color: #7b61ff;
  font-size: 12px;
  font-weight: bold;
}

::v-deep .markdown-description div {
  /* padding-left: 40px; */
  text-align: left;
  font-size: 12px;
}

::v-deep .markdown-description span {
  color: #7b61ff;
  font-size: 15px;
  font-weight: bold;
}

::v-deep .markdown-description h3 {
  margin-bottom: 10px;
}
</style>
