<template>
  <div class="free-fortune-result-page" v-if="freeFortuneResult">
    <v-row justify="center" align="center" class="mt-4" wrap>
      <v-col cols="12" sm="8" md="6" lg="4">
        <!-- 기본 정보 카드 -->
        <div
          style="color: #616161; font-size: 22px"
          justify="center"
          align="center"
        >
          <strong>무료 결과</strong>
        </div>

        <v-card class="pa-4 mb-4" elevation="0">
          <v-card-text class="text-left">
            <span style="color: #7b61ff; font-size: 20px; font-weight: bold">
              사주 정보
            </span>
            <div v-if="freeFortuneResult.member.sexType == 'MALE'">
              남성 ({{ freeFortuneResult.member.age }}세)
            </div>
            <div v-else>여성 ({{ freeFortuneResult.member.age }}세)</div>
            <div>
              <span v-if="freeFortuneResult.member.birthdayType == 'SOLAR'">
                양력
              </span>
              <span v-else>음력 </span>
              {{ freeFortuneResult.member.birthday }}
              <span v-if="freeFortuneResult.member.time"
                >({{ freeFortuneResult.member.time }})</span
              >
            </div>
          </v-card-text>
          <fortune-result-format-view
            :yearSky="freeFortuneResult.saju.yearSky"
            :yearGround="freeFortuneResult.saju.yearGround"
            :monthSky="freeFortuneResult.saju.monthSky"
            :monthGround="freeFortuneResult.saju.monthGround"
            :daySky="freeFortuneResult.saju.daySky"
            :dayGround="freeFortuneResult.saju.dayGround"
            :timeSky="freeFortuneResult.saju.timeSky"
            :timeGround="freeFortuneResult.saju.timeGround"
            :bigFortuneSky="freeFortuneResult.fortune.bigFortune.sky"
            :bigFortuneGround="freeFortuneResult.fortune.bigFortune.ground"
            :smallFortuneSky="freeFortuneResult.fortune.smallFortune.sky"
            :smallFortuneGround="freeFortuneResult.fortune.smallFortune.ground"
          />
        </v-card>

        <v-card
          class="pa-4 mb-4 saju-result"
          elevation="0"
          v-if="fortuneContent"
        >
          <v-card-text class="text-left">
            <v-row>
              <vue-markdown :key="freeFortuneResult.sajuAnalysisResult">{{
                freeFortuneResult.sajuAnalysisResult
              }}</vue-markdown>
            </v-row>
          </v-card-text>
          <v-card-text class="text-left">
            <vue-markdown :key="fortuneContent.marketingText">{{
              fortuneContent.marketingText
            }}</vue-markdown>
          </v-card-text>

          <v-card class="content-card" v-if="fortuneContent.recommendedContent">
            <v-list-item class="recommended-item">
              <v-col cols="3">
                <v-img
                  :src="fortuneContent?.recommendedContent?.thumbnailImageUrl"
                  alt="List Item Image"
                  class="list-item-image"
                ></v-img>
              </v-col>

              <v-col cols="9">
                <v-list-item-content
                  class="left-aligned-content"
                  @click="
                    handlePremiumItemClick(
                      fortuneContent?.recommendedContent?.id
                    )
                  "
                >
                  <v-list-item-title style="margin-bottom: 7px"
                    ><strong>{{
                      fortuneContent?.recommendedContent?.title
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <span style="color: gray; text-decoration: line-through">
                      {{
                        fortuneContent?.recommendedContent?.originalPrice?.toLocaleString(
                          "ko-KR"
                        )
                      }}원
                    </span>
                    ({{
                      fortuneContent?.recommendedContent?.discountPrice?.toLocaleString(
                        "ko-KR"
                      )
                    }}원)
                    <span style="color: #ff6b6b">
                      {{
                        calculateDiscountRate(
                          fortuneContent?.recommendedContent?.originalPrice,
                          fortuneContent?.recommendedContent?.discountPrice
                        )
                      }}% 할인
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
            </v-list-item>
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <!-- 로딩 오버레이 -->
    <v-overlay class="loading-center" v-if="loading" :value="loading" fixed>
      <v-progress-circular
        indeterminate
        size="80"
        width="7"
        color="#7b61ff"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueMarkdown from "vue-markdown";
import FortuneResultFormatView from "@/components/format/FortuneResultFormatView.vue";

export default {
  name: "FreeFortuneResultPage",
  data() {
    return {
      loading: false, // 로딩 상태 추가
      fortuneContentId: null,
      fortuneResultId: null,
      sexType: null,
      calendarType: null,
      birthday: null,
      time: null,
      memberId: null,
      memberType: null,
    };
  },
  components: {
    VueMarkdown,
    FortuneResultFormatView,
  },
  methods: {
    handlePremiumItemClick(contentId) {
      // contentId를 동적 파라미터로 전달
      this.$router.push({
        name: "PremiumFortuneContentPage",
        params: { id: contentId },
      });
    },
    calculateDiscountRate(originalPrice, discountPrice) {
      if (originalPrice && discountPrice) {
        const discountRate =
          ((originalPrice - discountPrice) / originalPrice) * 100;
        return Math.round(discountRate);
      }
      return 0;
    },

    ...mapActions([
      "analyzeFreeFortune",
      "analyzePremiumFortune",
      "setAccessToken",
      "sendEvent",
    ]),
  },
  computed: {
    ...mapState(["fortuneContent", "freeFortuneResult"]),
  },
  async mounted() {
    this.fortuneContentId = this.$route.query.fortuneContentId;
    this.memberType = this.$route.query.memberType;
    this.memberId = this.$route.query.memberId;
    this.sexType = this.$route.query.sexType;
    this.calendarType = this.$route.query.calendarType;
    this.birthday = this.$route.query.birthday;
    this.time = this.$route.query.time;
    this.accessToken = this.$route.query.code;

    //액세스 토큰이 있는 경우 commit SET_ACCESS_TOKEN
    if (this.accessToken) {
      console.log("액세스 토큰:", this.accessToken);
      await this.setAccessToken(this.accessToken);
    }

    await this.analyzeFreeFortune(this);

    try {
      const sessionId = localStorage.getItem("session_id");
      const utmCampaign = localStorage.getItem("utm_campaign");

      // API 호출
      this.sendEvent({
        sessionId: sessionId,
        eventType: "FREE_ANALYSIS",
        fortuneContentId: this.fortuneContentId,
        utmCampaign: utmCampaign,
      });
    } catch (e) {
      console.warn("localStorage is not available:", e);
    }
  },
};
</script>

<style scoped>
.loading-center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* fixed로 변경 */
  top: 0;
  left: 0;
  width: 100vw; /* 뷰포트 너비 */
  height: 100vh; /* 뷰포트 높이 */
  background: rgba(255, 255, 255, 0.8); /* 반투명 배경 */
  z-index: 1000; /* 다른 요소보다 위에 표시 */
}

.free-fortune-result-page {
  padding-top: 64px;
  padding-bottom: 80px; /* 버튼이 컨텐츠를 가리지 않도록 하단에 추가 여백 */
}

.premium-fortune-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px; /* 버튼 높이 */
  z-index: 9999; /* 패널 및 기타 요소보다 위에 오도록 */
  /* 추가로 필요한 스타일 */
  background-color: #ffd600 !important;
  color: #7b61ff !important;
  font-weight: bold;
  font-size: 15px;
}

.saju-result {
  margin: 10px;
  padding: 10px;
}

.custom-li-spacing li {
  /* 줄 높이(line-height) 조정: 1.5 ~ 2 정도가 보통 */
  line-height: 1.8;

  /* 아래 여백도 추가 가능 */
  margin-bottom: 8px;
}

.list-item-image {
  width: 50px; /* 원하는 이미지 너비로 설정 */
  height: 60px; /* 원하는 이미지 높이로 설정 */
  margin-right: 15px; /* 텍스트와 이미지 사이 간격 */
  object-fit: cover; /* 이미지가 요소 내에서 잘 맞도록 설정 */
  border-radius: 8px; /* 이미지 둥근 모서리 설정 */
}

.content-card {
  border-radius: 16px !important;
  margin: 8px !important;
  border: 1px solid #e0e0e0 !important;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.content-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
}

.v-list-item {
  padding: 8px !important;
}
</style>
