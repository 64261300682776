<template>
  <div class="fortune-result-format-view">
    <table>
      <tbody>
        <tr>
          <td>시</td>
          <td>일</td>
          <td>월</td>
          <td>년</td>

          <td class="no-border"></td>
          <td>대운</td>
          <td>세운</td>
        </tr>
        <tr>
          <fortune-sky :sky="timeSky" />
          <fortune-sky :sky="daySky" :flag="'daySky'" />
          <fortune-sky :sky="monthSky" />
          <fortune-sky :sky="yearSky" />

          <td class="no-border"></td>
          <fortune-sky :sky="bigFortuneSky" />
          <fortune-sky :sky="smallFortuneSky" />
        </tr>
        <tr>
          <fortune-ground :ground="timeGround" />
          <fortune-ground :ground="dayGround" />
          <fortune-ground :ground="monthGround" />
          <fortune-ground :ground="yearGround" />

          <td class="no-border"></td>
          <fortune-ground :ground="bigFortuneGround" />
          <fortune-ground :ground="smallFortuneGround" />
        </tr>
        <!-- 세 번째 줄 (지장간) -->
        <tr class="bottom">
          <fortune-jijanggan :ground="timeGround" />
          <fortune-jijanggan :ground="dayGround" />
          <fortune-jijanggan :ground="monthGround" />
          <fortune-jijanggan :ground="yearGround" />

          <td class="no-border"></td>
          <fortune-jijanggan :ground="bigFortuneGround" />
          <fortune-jijanggan :ground="smallFortuneGround" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import FortuneSky from "./FortuneSky";
import FortuneGround from "./FortuneGround";
import FortuneJijanggan from "./FortuneJijanggan";

export default {
  name: "FortuneResultFormatView",
  components: {
    FortuneSky,
    FortuneGround,
    FortuneJijanggan,
  },
  props: {
    yearSky: {
      type: Object,
    },
    monthSky: {
      type: Object,
    },
    daySky: {
      type: Object,
    },
    timeSky: {
      type: Object,
    },
    yearGround: {
      type: Object,
    },
    monthGround: {
      type: Object,
    },
    dayGround: {
      type: Object,
    },
    timeGround: {
      type: Object,
    },
    bigFortuneSky: {
      type: Object,
    },
    smallFortuneSky: {
      type: Object,
    },
    bigFortuneGround: {
      type: Object,
    },
    smallFortuneGround: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.no-border {
  border: none; /* 테두리를 없앰 */
  width: 30px; /* 빈 칸의 너비 */
}

.bottom {
  font-size: 12px;
}
table {
  border-collapse: collapse;
  width: 330px; /* 너비를 늘려서 열 확장 반영 */
  /* border: 1px solid black; */
  margin: 5px;
}
th,
td {
  width: 33px; /* 열의 너비를 넓혀서 추가된 데이터가 잘리지 않도록 */
  border: 1px solid black;
}
</style>
