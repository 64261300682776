<template>
  <v-app>
    <v-app-bar app :color="appBarColor" elevate-on-scroll fixed>
      <v-icon
        @click="goToHome"
        style="
          color: #ffd600;
          font-size: 45px;
          transform: rotate(90deg);
          transition: transform 0.3s;
        "
      >
        mdi-all-inclusive-box
      </v-icon>
      <v-toolbar-title @click="goToHome" class="app-title"
        ><span>에잇콘텐츠</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        class="app-drawer"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary right>
      <v-list>
        <v-list-item @click="handleCsMenuClick()">
          <v-list-item-title>1:1 문의</v-list-item-title>
        </v-list-item>
      </v-list>
      <!-- v-spacer로 메인 콘텐츠와 하단 고정 콘텐츠 사이의 공간 확보 -->
      <v-spacer></v-spacer>
    </v-navigation-drawer>

    <!-- 연애운 navigation drawer 추가 -->
    <v-navigation-drawer v-model="loveDrawer" app temporary right width="100%">
      <v-toolbar dark color="#7b61ff">
        <v-toolbar-title>연애운 보기</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="loveDrawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list two-line v-if="fortuneCategoryList" class="category-content">
        <v-list-item
          v-for="item in fortuneCategoryList.find(
            (item) => item.code === 'LOVE'
          ).fortuneContentCategory"
          :key="item.fortuneContent.id"
          @click="handleItemClick(item.fortuneContent.id)"
        >
          <v-col cols="3">
            <v-img
              :src="item.fortuneContent.thumbnailImageUrl"
              alt="List Item Image"
              class="list-item-image"
            ></v-img>
          </v-col>

          <v-col cols="9">
            <v-list-item-content class="left-aligned-content">
              <v-list-item-title style="margin-bottom: 7px">
                <strong>{{ item.fortuneContent.title }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle>
                <strong>(무료)</strong>
                {{ item.fortuneContent.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- 프리미엄 navigation drawer 추가 -->
    <v-navigation-drawer
      v-model="premiumDrawer"
      app
      temporary
      right
      width="100%"
    >
      <v-toolbar dark color="#7b61ff">
        <v-toolbar-title>프리미엄 보기</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="premiumDrawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list two-line v-if="fortuneCategoryList" class="category-content">
        <v-list-item
          v-for="item in fortuneCategoryList.find(
            (item) => item.code === 'PREMIUM'
          ).fortuneContentCategory"
          :key="item.fortuneContent.id"
          @click="handlePremiumItemClick(item.fortuneContent.id)"
        >
          <v-col cols="3">
            <v-img
              :src="item.fortuneContent.thumbnailImageUrl"
              alt="List Item Image"
              class="list-item-image"
            ></v-img>
          </v-col>

          <v-col cols="9">
            <v-list-item-content class="left-aligned-content">
              <v-list-item-title style="margin-bottom: 7px"
                ><strong>{{
                  item.fortuneContent.title
                }}</strong></v-list-item-title
              >
              <v-list-item-subtitle>
                <span style="color: gray; text-decoration: line-through">
                  {{
                    item.fortuneContent?.originalPrice?.toLocaleString("ko-KR")
                  }}원
                </span>
                ({{
                  item.fortuneContent?.discountPrice?.toLocaleString("ko-KR")
                }}원)
                <span style="color: #ff6b6b">
                  {{
                    calculateDiscountRate(
                      item.fortuneContent.originalPrice,
                      item.fortuneContent.discountPrice
                    )
                  }}% 할인
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- 솔로탈출 navigation drawer 추가 -->
    <v-navigation-drawer v-model="soloDrawer" app temporary right width="100%">
      <v-toolbar dark color="#7b61ff">
        <v-toolbar-title>솔로탈출 보기</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="soloDrawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list two-line v-if="fortuneCategoryList" class="category-content">
        <v-list-item
          v-for="item in fortuneCategoryList.find(
            (item) => item.code === 'SOLO'
          ).fortuneContentCategory"
          :key="item.fortuneContent.id"
          @click="handleItemClick(item.fortuneContent.id)"
        >
          <v-col cols="3">
            <v-img
              :src="item.fortuneContent.thumbnailImageUrl"
              alt="List Item Image"
              class="list-item-image"
            ></v-img>
          </v-col>

          <v-col cols="9">
            <v-list-item-content class="left-aligned-content">
              <v-list-item-title style="margin-bottom: 7px">
                <strong>{{ item.fortuneContent.title }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle>
                <strong>(무료)</strong>
                {{ item.fortuneContent.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- NEW navigation drawer 추가 -->
    <v-navigation-drawer v-model="newDrawer" app temporary right width="100%">
      <v-toolbar dark color="#7b61ff">
        <v-toolbar-title>NEW 보기</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="newDrawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list two-line v-if="fortuneCategoryList" class="category-content">
        <v-list-item
          v-for="item in fortuneCategoryList.find((item) => item.code === 'NEW')
            .fortuneContentCategory"
          :key="item.fortuneContent.id"
          @click="handleItemClick(item.fortuneContent.id)"
        >
          <v-col cols="3">
            <v-img
              :src="item.fortuneContent.thumbnailImageUrl"
              alt="List Item Image"
              class="list-item-image"
            ></v-img>
          </v-col>

          <v-col cols="9">
            <v-list-item-content class="left-aligned-content">
              <v-list-item-title style="margin-bottom: 7px">
                <strong>{{ item.fortuneContent.title }}</strong>
              </v-list-item-title>
              <v-list-item-subtitle>
                <strong>(무료)</strong>
                {{ item.fortuneContent.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-col>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <template v-if="$route.path === '/'">
      <div v-if="path === 'home'">
        <v-container fluid class="hero-section">
          <v-row align="center" justify="center">
            <v-col cols="12" md="8" class="text-center">
              <h2 class="hero-title">
                <!-- <span style="color: #ffd600">오직 나</span>만을 위한
                <span style="color: #ffd600">콘텐츠</span> -->
                <span style="color: #ffd600">내 사주</span>
                <span style="color: white">로 보는 </span>
                <span style="color: #ffd600">연애 콘텐츠</span>
              </h2>

              <!-- 첫 번째 줄 동그라미 -->
              <v-row justify="center" class="mt-6">
                <v-col
                  v-for="(item, i) in iconItems.slice(0, 4)"
                  :key="i"
                  cols="3"
                  sm="2"
                  class="text-center"
                >
                  <div class="circle-icon" @click="handleIconClick(item)">
                    <v-icon color="#ffd600">{{ item.icon }}</v-icon>
                  </div>
                  <div class="icon-text">{{ item.text }}</div>
                </v-col>
              </v-row>

              <!-- 두 번째 줄 동그라미 -->
              <!-- <v-row justify="center" class="mt-4">
                <v-col
                  v-for="(item, i) in iconItems.slice(4, 8)"
                  :key="i"
                  cols="3"
                  sm="2"
                  class="text-center"
                >
                  <div class="circle-icon">
                    <v-icon color="white">{{ item.icon }}</v-icon>
                  </div>
                  <div class="icon-text">{{ item.text }}</div>
                </v-col>
              </v-row> -->
            </v-col>
          </v-row>
        </v-container>

        <v-main class="main-content">
          <!-- 솔로탈출 BEST 카테고리 추가 -->
          <div class="category-header">
            <div class="category-title">솔로탈출 BEST</div>
            <div class="more-link" @click="soloDrawer = true">더보기</div>
          </div>
          <v-list two-line v-if="fortuneCategoryList" class="category-content">
            <v-list-item
              v-for="item in fortuneCategoryList
                .find((item) => item.code === 'SOLO')
                .fortuneContentCategory.slice(0, 3)"
              :key="item.fortuneContent.id"
              @click="handleItemClick(item.fortuneContent.id)"
            >
              <v-col cols="3">
                <v-img
                  :src="item.fortuneContent.thumbnailImageUrl"
                  alt="List Item Image"
                  class="list-item-image"
                ></v-img>
              </v-col>

              <v-col cols="9">
                <v-list-item-content class="left-aligned-content">
                  <v-list-item-title style="margin-bottom: 7px">
                    <strong>{{ item.fortuneContent.title }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <strong>(무료)</strong>
                    {{ item.fortuneContent.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
            </v-list-item>
          </v-list>

          <!-- 연애운 BEST 카테고리 -->
          <div class="category-header">
            <div class="category-title">연애운 BEST</div>
            <div class="more-link" @click="loveDrawer = true">더보기</div>
          </div>
          <v-list two-line v-if="fortuneCategoryList" class="category-content">
            <v-list-item
              v-for="item in fortuneCategoryList
                .find((item) => item.code === 'LOVE')
                .fortuneContentCategory.slice(0, 3)"
              :key="item.fortuneContent.id"
              @click="handleItemClick(item.fortuneContent.id)"
            >
              <v-col cols="3">
                <v-img
                  :src="item.fortuneContent.thumbnailImageUrl"
                  alt="List Item Image"
                  class="list-item-image"
                ></v-img>
              </v-col>

              <v-col cols="9">
                <v-list-item-content class="left-aligned-content">
                  <v-list-item-title style="margin-bottom: 7px"
                    ><strong>{{
                      item.fortuneContent.title
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle
                    ><strong>(무료)</strong>
                    {{ item.fortuneContent.subtitle }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-col>
            </v-list-item>
          </v-list>

          <!-- NEW BEST 카테고리 추가 -->
          <div class="category-header">
            <div class="category-title">신상 BEST</div>
            <div class="more-link" @click="newDrawer = true">더보기</div>
          </div>
          <v-list two-line v-if="fortuneCategoryList" class="category-content">
            <v-list-item
              v-for="item in fortuneCategoryList
                .find((item) => item.code === 'NEW')
                .fortuneContentCategory.slice(0, 3)"
              :key="item.fortuneContent.id"
              @click="handleItemClick(item.fortuneContent.id)"
            >
              <v-col cols="3">
                <v-img
                  :src="item.fortuneContent.thumbnailImageUrl"
                  alt="List Item Image"
                  class="list-item-image"
                ></v-img>
              </v-col>

              <v-col cols="9">
                <v-list-item-content class="left-aligned-content">
                  <v-list-item-title style="margin-bottom: 7px">
                    <strong>{{ item.fortuneContent.title }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <strong>(무료)</strong>
                    {{ item.fortuneContent.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
            </v-list-item>
          </v-list>

          <!-- 프리미엄 BEST 카테고리 -->
          <div class="category-header">
            <div class="category-title">프리미엄 BEST</div>
            <div class="more-link" @click="premiumDrawer = true">더보기</div>
          </div>
          <v-list two-line v-if="fortuneCategoryList" class="category-content">
            <v-list-item
              v-for="item in fortuneCategoryList
                .find((item) => item.code === 'PREMIUM')
                .fortuneContentCategory.slice(0, 3)"
              :key="item.fortuneContent.id"
              @click="handlePremiumItemClick(item.fortuneContent.id)"
            >
              <v-col cols="3">
                <v-img
                  :src="item.fortuneContent.thumbnailImageUrl"
                  alt="List Item Image"
                  class="list-item-image"
                ></v-img>
              </v-col>

              <v-col cols="9">
                <v-list-item-content class="left-aligned-content">
                  <v-list-item-title style="margin-bottom: 7px"
                    ><strong>{{
                      item.fortuneContent.title
                    }}</strong></v-list-item-title
                  >
                  <v-list-item-subtitle>
                    <span style="color: gray; text-decoration: line-through">
                      {{
                        item.fortuneContent?.originalPrice?.toLocaleString(
                          "ko-KR"
                        )
                      }}원
                    </span>
                    ({{
                      item.fortuneContent?.discountPrice?.toLocaleString(
                        "ko-KR"
                      )
                    }}원)
                    <span style="color: #ff6b6b">
                      {{
                        calculateDiscountRate(
                          item.fortuneContent.originalPrice,
                          item.fortuneContent.discountPrice
                        )
                      }}% 할인
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
            </v-list-item>
          </v-list>
        </v-main>
      </div>
      <div v-else-if="path === 'review'">
        <v-main class="main-content review" v-if="isAuthorized">
          <div class="d-flex align-center mb-3 title-section">
            <v-icon class="mr-2" style="color: gray">mdi-chat</v-icon>
            <div class="text-h5 font-weight-bold">다시 보기</div>
          </div>

          <v-container class="py-4" v-if="fortuneResultList">
            <v-row dense>
              <v-col
                v-for="(result, index) in fortuneResultList"
                :key="index"
                cols="12"
              >
                <!-- 테두리를 더 둥글게 만들기 위해 class를 추가 -->
                <v-card
                  elevation="0"
                  outlined
                  class="pa-3 mb-2 custom-rounded-card"
                  @click="
                    result.resultStatus !== 'NONE'
                      ? navigateToDetail(result.id)
                      : navigateToDetailFree(
                          result.fortuneContentId,
                          result.memberId
                        )
                  "
                >
                  <v-row align="center" no-gutters>
                    <!-- 이미지 -->
                    <v-col cols="auto" class="mr-3">
                      <v-img
                        v-if="result.resultStatus !== 'NONE'"
                        :src="result.fortuneContent.thumbnailImageUrl"
                        alt="main image"
                        class="list-item-image"
                      ></v-img>
                      <v-img
                        v-else
                        :src="result.fortuneContent.freeThumbnailImageUrl"
                        alt="main image"
                        class="list-item-image"
                      ></v-img>
                    </v-col>

                    <!-- 텍스트 영역 -->
                    <v-col>
                      <!-- 제목 -->
                      <div class="font-weight-bold text-subtitle-1">
                        <span v-if="result.resultStatus !== 'NONE'">
                          {{ result.fortuneContent.title }}
                        </span>
                        <span v-else>
                          (무료) {{ result.fortuneContent.freeTitle }}
                        </span>
                      </div>

                      <!-- 서브 정보 -->
                      <div class="text--secondary text-body-2 mt-1">
                        <!-- 양력/음력 정보 + 성별 -->
                        <div>
                          <!-- 3) 달력 아이콘 (양력/음력) -->
                          <v-icon
                            small
                            class="mr-1"
                            v-if="result.member.sexType === 'MALE'"
                            >mdi-gender-male</v-icon
                          >
                          <v-icon small class="mr-1" v-else
                            >mdi-gender-female</v-icon
                          >
                          <!-- 양력/음력 텍스트 -->
                          <span v-if="result.member.calendarType === 'SOLAR'">
                            양력
                          </span>
                          <span v-else> 음력 </span>
                          {{ result.member.birthday }}
                          <span v-if="result.member.time">
                            (시간: {{ result.member.time }})</span
                          >
                        </div>

                        <!-- 구매 날짜 / 무료 날짜 표시 -->
                        <div>
                          <!-- 4) 달력(또는 시계) 아이콘 -->
                          <v-icon small class="mr-1">mdi-calendar</v-icon>

                          {{
                            result.resultStatus === "NONE"
                              ? result.createdAt
                              : result.updatedAt
                          }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
        <v-main class="main-content" v-else>
          <v-container class="auth-container">
            <v-row justify="center" align="center" class="auth-content">
              <!-- 브랜드 / 로고 영역 -->
              <v-col cols="12" class="text-center mb-8">
                <div
                  class="font-weight-bold text-h4 mb-4 text-center justify-center d-flex align-center"
                >
                  <v-icon
                    style="
                      color: #ffd600;
                      font-size: 45px;
                      transform: rotate(90deg);
                      transition: transform 0.3s;
                    "
                  >
                    mdi-all-inclusive-box
                  </v-icon>
                  <v-toolbar-title>
                    <span style="color: #ffd600">에잇콘텐츠</span>
                    <!-- <span style="color: gray">Content</span> -->
                  </v-toolbar-title>
                </div>
                <div class="text-h6 font-weight-medium mb-2">
                  <strong>
                    <!-- <span style="color: #7b61ff">오직 나</span>
                    <span style="color: gray">만을 위한</span>
                    <span style="color: #7b61ff"> 콘텐츠</span> -->
                    <span style="color: #7b61ff">내 사주</span>
                    <span style="color: gray">로 보는</span>
                    <span style="color: #7b61ff"> 연애 콘텐츠</span>
                  </strong>
                </div>
              </v-col>
              <div>
                <strong style="color: #616161">로그인 후 이용해주세요</strong>
              </div>
              <br />

              <!-- (2) 간편 로그인 -->
              <v-col cols="12" class="text-center mb-6 d-flex justify-center">
                <span class="mb-3" style="color: #616161; font-weight: 600">
                  <v-img
                    width="200"
                    src="@/assets/kakao_login_medium_narrow.png"
                    style="cursor: pointer"
                    @click="kakaoLogin('review')"
                  />
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </div>
      <div v-else-if="path === 'profile'">
        <v-main class="main-content profile" v-if="isAuthorized">
          <div class="d-flex align-center mb-3 title-section">
            <v-icon class="mr-2" style="color: gray">mdi-account</v-icon>
            <div class="text-h5 font-weight-bold">마이페이지</div>
          </div>

          <v-container v-if="profile">
            <v-row justify="center" align="center" class="mt-5">
              <v-col
                cols="12"
                md="6"
                class="d-flex justify-center align-center"
                style="padding: 2rem; max-width: 450px; flex-direction: column"
              >
                <div
                  class="text-center"
                  style="font-size: 1rem; margin-bottom: 2rem"
                >
                  {{ profile.email }}
                </div>

                <v-btn color="secondary" class="white--text" @click="logout">
                  로그아웃
                </v-btn>
              </v-col>
            </v-row>

            <v-row justify="center" v-if="memberList.length > 0">
              <v-col cols="12" md="8">
                <!-- v-card에 곡선을 주고, 약간의 패딩과 outline 효과 -->
                <v-card elevation="0" outlined class="pa-3 rounded-card">
                  <v-list v-for="member in memberList" :key="member.id">
                    <!-- 리스트 아이템 -->
                    <v-list-item class="d-flex justify-space-between">
                      <v-list-item-content>
                        <!-- 2) 양력 / 음력 + 생년월일 -->
                        <v-list-item-subtitle class="text-line-height">
                          <v-icon
                            small
                            class="mr-1"
                            v-if="member.sexType === 'MALE'"
                            >mdi-gender-male</v-icon
                          >
                          <v-icon small class="mr-1" v-else
                            >mdi-gender-female</v-icon
                          >
                          <span v-if="member.calendarType === 'SOLAR'"
                            >양력</span
                          >
                          <span v-else>음력</span>
                          {{ member.birthday }}
                          <span v-if="member.time">
                            (시간: {{ member.time }})</span
                          >
                        </v-list-item-subtitle>

                        <!-- 4) 가입(생성) 날짜 -->
                        <v-list-item-subtitle class="text-line-height">
                          <v-icon small class="mr-1">mdi-calendar</v-icon>
                          {{ member.createdAt }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <!-- 상태 변경 버튼 -->
                      <v-list-item-action>
                        <!-- 상태 OFF -> 선택 가능 -->
                        <v-btn
                          v-if="member.status === 'OFF'"
                          color="#7b61ff"
                          outlined
                          @click="changeStatus(member.id)"
                        >
                          선택
                        </v-btn>
                        <!-- status ON -> 기본 (비활성화) -->
                        <v-btn v-else color="error" disabled> 기본 </v-btn>
                      </v-list-item-action>
                    </v-list-item>

                    <!-- 리스트 항목 사이 구분선 -->
                    <v-divider></v-divider>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
        <v-main class="main-content" v-else>
          <v-container class="auth-container">
            <v-row justify="center" align="center" class="auth-content">
              <!-- 브랜드 / 로고 영역 -->
              <v-col cols="12" class="text-center mb-8">
                <div
                  class="font-weight-bold text-h4 mb-4 text-center justify-center d-flex align-center"
                >
                  <v-icon
                    style="
                      color: #ffd600;
                      font-size: 45px;
                      transform: rotate(90deg);
                      transition: transform 0.3s;
                    "
                  >
                    mdi-all-inclusive-box
                  </v-icon>
                  <v-toolbar-title>
                    <span style="color: #ffd600">에잇콘텐츠</span>
                    <!-- <span style="color: gray">Content</span> -->
                  </v-toolbar-title>
                </div>
                <div class="text-h6 font-weight-medium mb-2">
                  <strong>
                    <!-- <span style="color: #7b61ff">오직 나</span>
                    <span style="color: gray">만을 위한</span>
                    <span style="color: #7b61ff"> 콘텐츠</span> -->
                    <span style="color: #7b61ff">내 사주</span>
                    <span style="color: gray">로 보는</span>
                    <span style="color: #7b61ff"> 연애 콘텐츠</span>
                  </strong>
                </div>
              </v-col>
              <div>
                <strong style="color: #616161">로그인 후 이용해주세요</strong>
              </div>
              <br />

              <!-- (2) 간편 로그인 -->
              <v-col cols="12" class="text-center mb-6 d-flex justify-center">
                <span class="mb-3" style="color: #616161; font-weight: 600">
                  <v-img
                    width="200"
                    src="@/assets/kakao_login_medium_narrow.png"
                    style="cursor: pointer"
                    @click="kakaoLogin('profile')"
                  />
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </div>
    </template>

    <router-view v-else />

    <!-- Footer -->
    <v-footer padless class="custom-footer">
      <v-container>
        <v-row justify="center">
          <v-col class="text-center" cols="12">
            <span class="footer-text">
              © 2025 에잇콘텐츠. All rights reserved.
            </span>
            <div class="business-info">
              <p>
                에잇콘텐츠 | 대표 구근회 | 사업자등록번호: 618-11-55525
                <br />
              </p>
              <p>주소 : 인천광역시 부평구 동수천로 71, 107동 1004호</p>
              <p>연락처 : 010-7764-2027 | 고객센터 : eightcontent.kakao.com</p>
              <a
                href="https://eight-content.gitbook.io/eight-content"
                class="link"
                target="_blank"
                rel="noopener noreferrer"
                >개인정보 처리방침</a
              >
              |
              <a
                href="https://eight-content.gitbook.io/eight-content/terms-of-service"
                class="link"
                target="_blank"
                rel="noopener noreferrer"
                >이용약관</a
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <!-- 바텀 네비게이션 추가 -->
    <v-bottom-navigation
      style="height: 56px"
      v-if="$route.path === '/'"
      v-model="bottomNav"
      app
      fixed
      grow
      dark
      color="#ffd600"
    >
      <v-btn @click="goToHome">
        <span class="footer-nav-text">홈</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn @click="goToReview">
        <span class="footer-nav-text">다시 보기</span>
        <v-icon>mdi-chat</v-icon>
      </v-btn>

      <v-btn @click="goToProfile">
        <span class="footer-nav-text">마이페이지</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<!-- 카카오 로그인 -->
<script
  src="https://t1.kakaocdn.net/kakao_js_sdk/2.7.0/kakao.min.js"
  integrity="sha384-l+xbElFSnPZ2rOaPrU//2FF5B4LB8FiX5q4fXYTlfcG4PGpMkE1vcL7kNXI6Cci0"
  crossorigin="anonymous"
></script>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      path: "home", // 현재 경로
      authWatch: null, // 인증 상태 관찰자
      bottomNav: null, // 바텀 네비게이션 선택된 항목
      error: false,
      drawer: false,
      loveDrawer: false, // 연애운 drawer를 위한 새로운 변수
      premiumDrawer: false, // 프리미엄 drawer를 위한 새로운 변수
      soloDrawer: false,
      newDrawer: false,
      iconItems: [
        { icon: "mdi-alarm", text: "NEW" },
        { icon: "mdi-star", text: "프리미엄" },
        { icon: "mdi-account-heart", text: "솔로탈출" },
        { icon: "mdi-cards-heart", text: "연애운" },
        { icon: "mdi-moon-full", text: "월운" },
        { icon: "mdi-sun", text: "일운" },
        { icon: "mdi-zodiac-libra", text: "사주" },
        { icon: "mdi-compass", text: "운세" },
      ],
      dialog: false,
      selectedCategory: null,
    };
  },
  async created() {
    this.getFortuneCategoryList();
    this.isAuthorized ? this.getFortuneResultList() : null;
    this.isAuthorized ? this.getMemberList() : null;
    this.isAuthorized ? this.getProfile() : null;
  },
  watch: {
    // 로그인 여부가 바뀔 때마다(즉 false -> true) 필요한 데이터 가져오기
    isAuthorized(newVal) {
      if (newVal) {
        this.getFortuneResultList();
        this.getMemberList();
        this.getProfile();
      }
    },
  },
  async mounted() {
    this.accessToken = this.$route.query.code;

    //액세스 토큰이 있는 경우 commit SET_ACCESS_TOKEN
    if (this.accessToken) {
      console.log("액세스 토큰:", this.accessToken);
      await this.setAccessToken(this.accessToken);
      this.path = this.$route.query.path;
    }
  },
  computed: {
    appBarColor() {
      return this.$route.path === "/"
        ? this.path === "home"
          ? "#FFF"
          : "#7b61ff"
        : "#7b61ff";
    },

    ...mapState([
      "fortuneCategoryList",
      "profile",
      "fortuneResultList",
      "memberList",
    ]),
    ...mapGetters(["isAuthorized"]),
  },
  methods: {
    handleCsMenuClick() {
      window.open("https://pf.kakao.com/_DUijn/chat");
      this.drawer = false;
    },
    //카카오 로그인
    kakaoLogin(path) {
      if (!window.Kakao) {
        console.error("Kakao SDK not loaded");
        return;
      }
      const sessionId = localStorage.getItem("session_id");
      const utmCampaign = localStorage.getItem("utm_campaign");
      const redirectUri = `${process.env.VUE_APP_API_URL}/v1/auth/kakao/home`;
      const stateObj = {
        path: path,
        sessionId: sessionId,
        utmCampaign: utmCampaign,
      };

      Kakao.Auth.authorize({
        redirectUri: redirectUri,
        state: JSON.stringify(stateObj),
        throughTalk: false,
      });
    },

    calculateDiscountRate(originalPrice, discountPrice) {
      if (originalPrice && discountPrice) {
        const discountRate =
          ((originalPrice - discountPrice) / originalPrice) * 100;
        return Math.round(discountRate);
      }
      return 0;
    },
    async changeStatus(memberId) {
      try {
        // updateStatus API 호출
        await this.updateStatus(memberId);
        // 성공 시 멤버 리스트 상태 업데이트 (예: 다시 가져오거나 프론트엔드에서 직접 변경)
        await this.getMemberList();
      } catch (error) {
        console.error("상태 변경 실패:", error);
      }
    },

    navigateToDetail(fortuneResultId) {
      if (fortuneResultId) {
        this.$router.push({
          name: "PremiumFortuneResultPage",
          params: { id: fortuneResultId },
        });
      }
    },
    navigateToDetailFree(fortuneContentId, memberId) {
      if (fortuneContentId && memberId) {
        console.log(fortuneContentId, memberId);
        this.$router.push({
          name: "FreeFortuneResultPage",
          query: {
            fortuneContentId: fortuneContentId,
            memberType: "existing",
            memberId: memberId,
          },
        });
      }
    },
    logout() {
      console.log("로그아웃 성공");
      this.signout();
      this.goToHome();
    },
    handleItemClick(contentId) {
      this.loveDrawer = false;
      this.$router.push({
        name: "FreeFortuneContentPage",
        params: { id: contentId },
      });
    },
    handlePremiumItemClick(contentId) {
      // contentId를 동적 파라미터로 전달
      this.$router.push({
        name: "PremiumFortuneContentPage",
        params: { id: contentId },
      });
    },
    goToHome() {
      this.path = "home";
      this.bottomNav = 0;
      if (this.$route.path !== "/") {
        this.$router.push({ path: "/" }).catch(() => {});
      }
    },
    async goToReview() {
      this.path = "review";
      console.log("review");
      this.isAuthorized ? await this.getFortuneResultList() : null;
    },
    async goToProfile() {
      this.path = "profile";
      this.isAuthorized ? await this.getMemberList() : null;
      this.isAuthorized ? await this.getProfile() : null;
    },
    handleIconClick(item) {
      if (item.text === "연애운") {
        this.loveDrawer = true;
      } else if (item.text === "프리미엄") {
        this.premiumDrawer = true;
      } else if (item.text === "솔로탈출") {
        this.soloDrawer = true;
      } else if (item.text === "NEW") {
        this.newDrawer = true;
      }
    },
    ...mapActions([
      "getFortuneCategoryList",
      "signin",
      "signout",
      "getFortuneResultList",
      "getMemberList",
      "getProfile",
      "updateStatus",
      "setAccessToken",
    ]),
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Roboto:wght@400;700&display=swap");

.review {
  margin-bottom: 300px;
}
.profile {
  margin-bottom: 100px;
}

/* 실제 내부 컨테이너에 max-width를 적용하여
   화면이 넓어도 일정 너비로 고정되도록 함 */
.auth-container {
  max-width: 390px; /* PC에서도 400px로 고정 */
  margin: 0 auto; /* 중앙 정렬 */
  margin-top: 60px; /* 상단 여백 자유롭게 조절 */
  margin-bottom: 400px;
}

.title-section {
  margin: 20px;
  color: gray;
  font-size: 2rem;
}

.custom-rounded-card {
  border-radius: 16px;
  border: 0.2px solid #ccc; /* 예: #ccc 혹은 다른 색상 */
}

/* 첫 번째 컨테이너 스타일 */
.refund-container {
  background-color: #f5f5f5; /* 연한 회색 배경 */
  padding: 20px 0;
  padding-left: 15px;
  padding-right: 15px;
  color: #333333; /* 텍스트 색상 */
  width: 100%;
}

.refund-content {
  text-align: left; /* 텍스트 왼쪽 정렬 */
}

.text-center {
  text-align: center; /* 제목 중앙 정렬 */
}

.refund-container h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

.refund-container h4 {
  font-weight: normal;
  margin-bottom: 15px;
}

.refund-guidelines {
  /* list-style: disc; */
  padding-left: 20px;
  margin: 0;
}

.refund-guidelines li {
  margin-bottom: 10px;
  font-size: 14px;
}
body {
  font-family: "Roboto", sans-serif;
}

.app-title {
  margin-left: 5px;
  cursor: pointer;
  font-weight: bold;
}
.app-title span {
  color: #ffd600;
}

.hero-section {
  background: #7b61ff;
  color: white;
  padding: 60px 0;
  text-align: center;
  margin-top: 50px;
  margin-bottom: -25px;
  padding-bottom: -25px;
}

.hero-title {
  font-size: 30px;
  font-weight: bold;
}

.hero-subtitle {
  font-size: 23px;
  margin-top: 20px;
}

.hero-btn {
  color: black;
}

/* main-content의 여백을 늘림 */
.main-content {
  margin-right: 550px;
  margin-left: 550px;
}

.main-container {
  padding-top: 0;
  margin-top: -50px;
}

.list-item-image {
  width: 50px;
  height: 60px;
  margin-right: 15px;
  object-fit: cover;
  border-radius: 8px;
}

.v-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* 리스트 항목 간의 구분선 */
  padding: 8px !important;
  cursor: pointer;
}

.v-list-item:hover {
  background-color: rgba(123, 97, 255, 0.1);
}

.left-aligned-content {
  text-align: left;
}

/* 이미지 크기 및 마진 조정 */
.list-item-image2 {
  width: 60px;
  height: 70px;
  border-radius: 8px;
  margin-right: 10px;
  object-fit: cover;
}

/* 카드 간 간격 조정 */
.compact-card {
  padding: 8px 12px;
  margin-bottom: 0px;
}

/* 텍스트 라인 높이 */
.text-line-height {
  line-height: 1.5;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 5px;
  padding: 5px; */
  text-align: left; /* 텍스트를 좌측 정렬로 설정 */
  margin: 0px 20px 0px; /* 상단, 좌측, 하단 여백 설정 */
  border-bottom: 2px solid #e0e0e0; /* 헤더 전체에 밑줄 추가 */
}

.more-link {
  color: #7b61ff;
  cursor: pointer;
  font-size: 14px;
}

.more-link:hover {
  opacity: 0.8;
}

.category-title {
  font-size: 22px;
  font-weight: 600;
  color: #7b61ff; /* 텍스트 색상 */
  text-align: left; /* 텍스트를 좌측 정렬로 설정 */

  padding-bottom: 5px;
  letter-spacing: 0.5px; /* 글자 간격 */
}

/* 카테고리 콘텐츠 영역 패딩 조정 */
.category-content {
  padding: 0px 16px 16px 16px;
  margin-bottom: 30px;
}

.custom-footer {
  margin-top: 280px;
  padding: 0 !important; /* 패딩 제거 */
  position: relative; /* 위치 조정 */
  bottom: 56px; /* 네비게이션 바 높이 만큼 위로 조정 */
  width: 100%;
  background-color: #f8f9faa0; /* 푸터 배경 색상 */
  color: #6c757d;
  font-size: 14px;
}
.member-list {
  margin-top: 100px;
}

.footer-text {
  font-weight: bold;
}

.business-info p {
  margin: 5px 0;
}

.link {
  color: #000;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.circle-icon {
  cursor: pointer;
  width: 70px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.circle-icon:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.3);
}

.headline {
  color: #7b61ff;
  padding: 16px;
  display: flex;
  align-items: center;
}

.icon-text {
  color: white;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 768px) {
  /* 수평 스크롤이 생기지 않도록 설정 */
  html,
  body,
  #app,
  .v-application {
    overflow-x: hidden; /* 수평 스크롤바 완전히 제거 */
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .v-app {
    width: 100%; /* 전체 화면 너비 차지 */
    overflow-x: hidden; /* 수평 스크롤 방지 */
  }
  .app-bar {
    width: 100%; /* 화면 너비를 가득 채움 */
    box-sizing: border-box; /* padding 및 border를 포함한 크기 계산 */
  }
  .hero-section {
    width: 100%;
  }

  .hero-section {
    padding-top: 40px; /* 모바일에서 상단 여백 줄이기 */
    padding-bottom: 40px; /* 모바일에서 하단 여백 줄이기 */
  }

  .main-content {
    margin: 0; /* main-content의 기본 여백 제거 */
    padding: 0; /* main-content의 기본 패딩 제거 */
  }

  /* main-container에 상단 간격을 제거 */
  .main-container {
    padding-top: 0px; /* 상단 패딩 제거 */
    margin-top: 0px; /* 상단 여백 제거 */
  }

  .hero-title {
    font-size: 30px;
  }

  .hero-subtitle {
    margin-top: 20px;
    font-size: 18px;
  }

  .v-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .circle-icon {
    width: 55px;
    height: 55px;
  }

  .circle-icon .v-icon {
    font-size: 24px;
  }

  .icon-text {
    font-size: 12px;
    margin-top: 4px;
  }
}
</style>
