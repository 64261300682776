import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Cookies from "js-cookie";
import "babel-polyfill";
import VueGtag from "vue-gtag";

Vue.config.productionTip = false;

const savedToken = Cookies.get("accessToken");
if (savedToken) {
  store.dispatch("signinByToken", savedToken);
}

if (window.Kakao) {
  window.Kakao.init("f2e443c2377edbf2f1bca9ef2d1b5ea9");
  console.log("Kakao SDK initialized:", window.Kakao.isInitialized()); // true
}

// VueGtag 초기화
if (process.env.NODE_ENV === "production") {
  Vue.use(
    VueGtag,
    {
      config: {
        id: "G-2W4G6MRQY8",
      },
    },
    router
  );
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
