import {
  SET_ACCESS_TOKEN,
  SET_PROFILE,
  DESTROY_ACCESS_TOKEN,
  DESTROY_PROFILE,
  SET_FORTUNE_CONTENT,
  SET_FORTUNE_CATEGORY_LIST,
  SET_FORTUNE_RESULT_LIST,
  SET_MEMBER_LIST,
  SET_FREE_FORTUNE_RESULT,
  SET_PREMIUM_FORTUNE_RESULT,
  SET_MEMBER_SAJU_RESULT,
} from "./mutations-types";
import api from "@/api";

export default {
  //액세스 토큰 저장
  async setAccessToken({ commit }, accessToken) {
    commit(SET_ACCESS_TOKEN, accessToken);

    const resProfile = await api.get("/v1/profile");
    if (resProfile) {
      commit(SET_PROFILE, resProfile.data.data.profile);
    }
  },

  //토큰이 있는 경우 내정보 가져오기
  async signinByToken({ commit }, token) {
    commit(SET_ACCESS_TOKEN, token);

    try {
      if (token) {
        const res = await api.get("/v1/profile");
        if (res) {
          return commit(SET_PROFILE, res.data.data);
        }
      } else {
        this.$router.push({ name: "SignUpPage" });
      }
    } catch (err) {
      this.$router.push({ name: "SignUpPage" });
    }
  },

  //프로필 가져오기
  async getProfile({ commit }) {
    try {
      const res = await api.get(`/v1/profile`);

      if (res) {
        return commit(SET_PROFILE, res.data.data.profile);
      }
    } catch (err) {
      console.log("Error : getProfile");
    }
  },

  //로그아웃
  signout({ commit }) {
    commit(DESTROY_PROFILE);
    commit(DESTROY_ACCESS_TOKEN);
  },

  //운세 컨텐츠 가져오기
  async getFortuneContent({ commit }, id) {
    try {
      const res = await api.get(`/v1/fortune-content/${id}`);
      if (res) {
        return commit(SET_FORTUNE_CONTENT, res.data.data.fortuneContent);
      }
    } catch (err) {
      return alert("운세 컨텐츠 가져오기 에러");
    }
  },

  //운세 카테고리 리스트 가져오기
  async getFortuneCategoryList({ commit }) {
    try {
      const res = await api.get(`/v1/fortune-category/list`);
      if (res) {
        return commit(
          SET_FORTUNE_CATEGORY_LIST,
          res.data.data.fortuneCategoryList
        );
      }
    } catch (err) {
      return alert("운세 카테고리 리스트 가져오기 에러");
    }
  },

  //운세 결과 리스트 가져오기
  async getFortuneResultList({ commit }) {
    try {
      const res = await api.get(`/v1/fortune-result/list`);
      if (res) {
        return commit(SET_FORTUNE_RESULT_LIST, res.data.data.fortuneResultList);
      }
    } catch (err) {
      console.log("운세 결과 리스트 가져오기 에러");
    }
  },

  //멤버 리스트 가져오기
  async getMemberList({ commit }) {
    try {
      const res = await api.get(`/v1/member/list`);
      if (res) {
        return commit(SET_MEMBER_LIST, res.data.data.memberList);
      }
    } catch (err) {
      console.log("멤버 리스트 가져오기 에러");
    }
  },

  //멤버 디폴트 값 설정
  async updateStatus({ commit }, memberId) {
    commit;
    try {
      await api.patch(`/v1/member/${memberId}`);
    } catch (err) {
      console.log(err);
      return alert("멤버 디폴트 값 설정 에러");
    }
  },

  //멤버 사주 분석
  async analyzeMemberSaju({ commit }, payload) {
    try {
      const {
        fortuneContentId,
        memberType,
        memberId,
        sexType,
        calendarType,
        birthday,
        time,
      } = payload;

      const res = await api.post(
        `/v1/fortune-content/${fortuneContentId}/member-saju-analysis`,
        {
          memberType,
          memberId,
          sexType,
          calendarType,
          birthday,
          time,
        }
      );
      if (res) {
        commit(SET_FORTUNE_CONTENT, res.data.data.fortuneContent);
        return commit(SET_MEMBER_SAJU_RESULT, res.data.data.memberSajuResult);
      }
    } catch (err) {
      return alert("멤버 사주 분석 에러");
    }
  },

  //무료 운세 분석
  async analyzeFreeFortune({ commit }, payload) {
    try {
      const {
        fortuneContentId,
        memberType,
        memberId,
        sexType,
        calendarType,
        birthday,
        time,
      } = payload;

      const res = await api.post(
        `/v1/fortune-content/${fortuneContentId}/free-fortune-analysis`,
        {
          memberType,
          memberId,
          sexType,
          calendarType,
          birthday,
          time,
        }
      );
      if (res) {
        commit(SET_FORTUNE_CONTENT, res.data.data.fortuneContent);
        return commit(SET_FREE_FORTUNE_RESULT, res.data.data.freeFortuneResult);
      }
    } catch (err) {
      return alert("무료 운세 분석 에러");
    }
  },

  //프리미엄 운세 분석
  async analyzePremiumFortune({ commit }, payload) {
    commit;

    const { fortuneResultId, orderId } = payload;

    try {
      return await api.post(`/v1/fortune-content/premium-fortune-analysis`, {
        fortuneResultId: fortuneResultId,
        orderId: orderId,
      });
    } catch (err) {
      return alert("프리미엄 운세 분석 권한이 없습니다.");
    }
  },

  //프리미엄 운세 분석 결과 가져오기
  async getPremiumFortuneResult({ commit }, fortuneResultId) {
    try {
      const res = await api.get(
        `/v1/fortune-result/${fortuneResultId}/premium-fortune-analysis`
      );
      if (res) {
        return commit(
          SET_PREMIUM_FORTUNE_RESULT,
          res.data.data.premiumFortuneResult
        );
      }
    } catch (err) {
      return alert("프리미엄 운세 분석 결과 가져오기 에러");
    }
  },

  //주문 중복 체크
  async checkPayment({ commit }, payload) {
    commit;

    const {
      fortuneContentId,
      memberId,
      orderId,
      memberType,
      sexType,
      calendarType,
      birthday,
      time,
    } = payload;

    try {
      const res = await api.post(`/v1/payment/check`, {
        fortuneContentId,
        memberId,
        orderId,
        memberType,
        sexType,
        calendarType,
        birthday,
        time,
      });
      console.log(res.data.statusCode);
      return res.data.statusCode;
    } catch (err) {
      return alert("주문 중복 체크 에러");
    }
  },

  //이벤트 전송
  async sendEvent({ commit }, payload) {
    commit;
    const {
      sessionId,
      eventType,
      fortuneContentId,
      referer,
      userAgent,
      utmSource,
      utmMedia,
      utmCampaign,
    } = payload;

    try {
      return await api.post(`/campaign-events`, {
        sessionId,
        eventType,
        fortuneContentId,
        referer,
        userAgent,
        utmSource,
        utmMedia,
        utmCampaign,
      });
    } catch (err) {
      return alert("이벤트 전송 에러");
    }
  },
};
