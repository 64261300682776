export default {
  accessToken: null,
  profile: null,
  fortuneContent: null, //운세 컨텐츠
  fortuneCategoryList: null, //운세 카테고리 리스트
  fortuneResultList: null, //운세 결과 리스트
  memberList: null, //멤버 리스트
  freeFortuneResult: null, //무료 운세 결과
  premiumFortuneResult: null, //프리미엄 운세 결과
  memberSajuResult: null, //멤버 사주 결과
};
