import {
  SET_ACCESS_TOKEN,
  SET_PROFILE,
  DESTROY_ACCESS_TOKEN,
  DESTROY_PROFILE,
  SET_FORTUNE_CONTENT,
  SET_FORTUNE_CATEGORY_LIST,
  SET_FORTUNE_RESULT_LIST,
  SET_MEMBER_LIST,
  SET_FREE_FORTUNE_RESULT,
  SET_PREMIUM_FORTUNE_RESULT,
  SET_MEMBER_SAJU_RESULT,
} from "./mutations-types";
import api from "@/api";
import Cookies from "js-cookie";

export default {
  [SET_ACCESS_TOKEN](state, accessToken) {
    if (accessToken) {
      state.accessToken = accessToken;
      api.defaults.headers.common["Authorization"] = `bearer ${accessToken}`;

      Cookies.set("accessToken", accessToken, { expires: 7 });
    }
  },
  [SET_PROFILE](state, profile) {
    if (profile) {
      state.profile = profile;
    }
  },
  [DESTROY_ACCESS_TOKEN](state) {
    state.accessToken = "";
    delete api.defaults.headers.common["Authorization"];
    Cookies.remove("accessToken");
  },
  [DESTROY_PROFILE](state) {
    state.profile = null;
  },

  [SET_FORTUNE_CONTENT](state, fortuneContent) {
    if (fortuneContent) {
      state.fortuneContent = fortuneContent;
    }
  },
  [SET_FORTUNE_CATEGORY_LIST](state, fortuneCategoryList) {
    if (fortuneCategoryList) {
      state.fortuneCategoryList = fortuneCategoryList;
    }
  },
  [SET_FORTUNE_RESULT_LIST](state, fortuneResultList) {
    if (fortuneResultList) {
      state.fortuneResultList = fortuneResultList;
    }
  },
  [SET_MEMBER_LIST](state, memberList) {
    if (memberList) {
      state.memberList = memberList;
    }
  },
  [SET_FREE_FORTUNE_RESULT](state, freeFortuneResult) {
    if (freeFortuneResult) {
      state.freeFortuneResult = freeFortuneResult;
    }
  },
  [SET_PREMIUM_FORTUNE_RESULT](state, premiumFortuneResult) {
    if (premiumFortuneResult) {
      state.premiumFortuneResult = premiumFortuneResult;
    }
  },
  [SET_MEMBER_SAJU_RESULT](state, memberSajuResult) {
    if (memberSajuResult) {
      state.memberSajuResult = memberSajuResult;
    }
  },
};
