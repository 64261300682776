<template>
  <div class="premium-fortune-result-page">
    <v-row justify="center" align="center" class="mt-4" wrap>
      <v-col cols="12" sm="8" md="6" lg="4" class="text-center">
        <div style="color: #616161; font-size: 22px">
          <strong>프리미엄 결과</strong>
        </div>
        <!-- Basic Information Card -->
        <v-card class="pa-4 mb-4" elevation="0" v-if="premiumFortuneResult">
          <v-card-text class="text-left">
            <span style="color: #7b61ff; font-size: 20px; font-weight: bold">
              사주 정보
            </span>

            <div v-if="premiumFortuneResult.member?.sexType === 'MALE'">
              남성 ({{ premiumFortuneResult.memberPrompt?.나이 }}세)
            </div>
            <div v-else>
              여성 ({{ premiumFortuneResult.memberPrompt?.나이 }}세)
            </div>
            <div>
              <span
                v-if="premiumFortuneResult.member?.calendarType === 'SOLAR'"
              >
                양력
              </span>
              <span v-else>음력</span>
              {{ premiumFortuneResult.member?.birthday }}
              <span v-if="premiumFortuneResult.member?.time"
                >({{ premiumFortuneResult.member?.time }})</span
              >
            </div>
          </v-card-text>

          <fortune-result-format-view
            v-if="premiumFortuneResult.saju"
            :yearSky="premiumFortuneResult.saju.yearSky"
            :yearGround="premiumFortuneResult.saju.yearGround"
            :monthSky="premiumFortuneResult.saju.monthSky"
            :monthGround="premiumFortuneResult.saju.monthGround"
            :daySky="premiumFortuneResult.saju.daySky"
            :dayGround="premiumFortuneResult.saju.dayGround"
            :timeSky="premiumFortuneResult.saju.timeSky"
            :timeGround="premiumFortuneResult.saju.timeGround"
            :bigFortuneSky="premiumFortuneResult.fortune.bigFortune.sky"
            :bigFortuneGround="premiumFortuneResult.fortune.bigFortune.ground"
            :smallFortuneSky="premiumFortuneResult.fortune.smallFortune.sky"
            :smallFortuneGround="
              premiumFortuneResult.fortune.smallFortune.ground
            "
          />
        </v-card>

        <!-- Fortune Result Content Card -->
        <v-card class="pa-4 mb-4" elevation="0" v-if="premiumFortuneResult">
          <v-card-text class="text-left">
            <vue-markdown :key="premiumFortuneResult.fortuneAnalysisResult">
              {{ premiumFortuneResult.fortuneAnalysisResult }}
            </vue-markdown>
          </v-card-text>
          <v-card-text class="text-left">
            <vue-markdown
              :key="premiumFortuneResult?.fortuneContent?.marketingText"
              >{{
                premiumFortuneResult?.fortuneContent?.marketingText
              }}</vue-markdown
            >
          </v-card-text>

          <v-card
            class="content-card"
            v-if="premiumFortuneResult?.fortuneContent?.recommendedContent"
          >
            <v-list-item class="recommended-item">
              <v-col cols="3">
                <v-img
                  :src="
                    premiumFortuneResult?.fortuneContent?.recommendedContent
                      ?.thumbnailImageUrl
                  "
                  alt="List Item Image"
                  class="list-item-image"
                ></v-img>
              </v-col>

              <v-col cols="10" class="content-col">
                <v-list-item-content
                  class="left-aligned-content"
                  @click="
                    handlePremiumItemClick(
                      premiumFortuneResult?.fortuneContent?.recommendedContent
                        ?.id
                    )
                  "
                >
                  <v-list-item-title class="content-title">
                    <strong>{{
                      premiumFortuneResult?.fortuneContent?.recommendedContent
                        ?.title
                    }}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle class="content-price">
                    <span style="color: gray; text-decoration: line-through">
                      {{
                        premiumFortuneResult?.fortuneContent?.recommendedContent?.originalPrice?.toLocaleString(
                          "ko-KR"
                        )
                      }}원
                    </span>
                    ({{
                      premiumFortuneResult?.fortuneContent?.recommendedContent?.discountPrice?.toLocaleString(
                        "ko-KR"
                      )
                    }}원)
                    <span style="color: #ff6b6b">
                      {{
                        calculateDiscountRate(
                          premiumFortuneResult?.fortuneContent
                            ?.recommendedContent?.originalPrice,
                          premiumFortuneResult?.fortuneContent
                            ?.recommendedContent?.discountPrice
                        )
                      }}% 할인
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>
            </v-list-item>
          </v-card>
        </v-card>
      </v-col>
    </v-row>

    <!-- 로딩 오버레이 -->
    <v-overlay
      :value="loading"
      opacity="0.9"
      class="d-flex align-center justify-center"
    >
      <v-progress-circular
        indeterminate
        size="64"
        width="7"
        color="#7b61ff"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FortuneResultFormatView from "@/components/format/FortuneResultFormatView.vue";
import VueMarkdown from "vue-markdown";

export default {
  name: "PremiumFortuneResultPage",
  data() {
    return {
      fortuneResultId: null,
      loading: false,
    };
  },
  components: {
    FortuneResultFormatView,
    VueMarkdown,
  },
  computed: {
    ...mapState(["premiumFortuneResult"]),
  },
  methods: {
    handlePremiumItemClick(contentId) {
      // contentId를 동적 파라미터로 전달
      this.$router.push({
        name: "PremiumFortuneContentPage",
        params: { id: contentId },
      });
    },
    calculateDiscountRate(originalPrice, discountPrice) {
      if (originalPrice && discountPrice) {
        const discountRate =
          ((originalPrice - discountPrice) / originalPrice) * 100;
        return Math.round(discountRate);
      }
      return 0;
    },

    ...mapActions(["getPremiumFortuneResult"]),
  },
  async mounted() {
    this.fortuneResultId = this.$route.params.id;
    if (this.fortuneResultId) {
      try {
        await this.getPremiumFortuneResult(this.fortuneResultId);
      } catch (error) {
        console.error("프리미엄 운세 결과 가져오기 실패:", error);
      }
    }
  },
};
</script>

<style scoped>
.premium-fortune-result-page {
  padding-top: 64px;
  padding-bottom: 80px;
}

.custom-li-spacing li {
  /* 줄 높이(line-height) 조정: 1.5 ~ 2 정도가 보통 */
  line-height: 1.8;

  /* 아래 여백도 추가 가능 */
  margin-bottom: 8px;
}

.list-item-image {
  width: 50px;
  height: 60px;
  margin-right: 8px;
  padding-right: 0;
  object-fit: cover;
  border-radius: 8px;
}

.v-list-item {
  padding: 8px !important;
}

.content-card {
  border-radius: 16px !important;
  margin: 8px !important;
  border: 1px solid #e0e0e0 !important;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.content-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
}

.content-col {
  display: flex;
  align-items: flex-start;
}

.content-title {
  text-align: left !important;
  padding-left: 0 !important;
  margin-bottom: 7px;
}

.content-price {
  text-align: left !important;
  padding-left: 0 !important;
}

.left-aligned-content {
  padding-left: 0px !important;
  margin-left: 0px !important;
}
</style>
