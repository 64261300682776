<template>
  <td v-if="ground">
    <span>{{ ground.jijangGan.first.chinese }}</span>
    <span v-if="ground.jijangGan.second">{{
      ground.jijangGan.second.chinese
    }}</span>
    <span v-else>#</span>
    <span>{{ ground.jijangGan.third.chinese }}</span>
  </td>
  <td v-else class="block"></td>
</template>

<script>
export default {
  name: "FortuneJijanggan",
  props: {
    ground: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>
.block {
  background-color: #cfd8dc;
}
</style>
