export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_PROFILE = "SET_PROFILE";
export const DESTROY_ACCESS_TOKEN = "DESTROY_ACCESS_TOKEN";
export const DESTROY_PROFILE = "DESTROY_PROFILE";
export const SET_FORTUNE_CONTENT = "SET_FORTUNE_CONTENT";
export const SET_FORTUNE_CATEGORY_LIST = "SET_FORTUNE_CATEGORY_LIST";
export const SET_FORTUNE_RESULT_LIST = "SET_FORTUNE_RESULT_LIST";
export const SET_MEMBER_LIST = "SET_MEMBER_LIST";
export const SET_FREE_FORTUNE_RESULT = "SET_FREE_FORTUNE_RESULT";
export const SET_PREMIUM_FORTUNE_RESULT = "SET_PREMIUM_FORTUNE_RESULT";
export const SET_MEMBER_SAJU_RESULT = "SET_MEMBER_SAJU_RESULT";
