import axios from "axios";

console.log(process.env.NODE_ENV);
export default process.env.NODE_ENV == "production"
  ? axios.create({
      //production 환경 (AWS EC2)
      baseURL: "https://api.eightcontent.com",
      //test 환경
      // baseURL: "http://54.180.171.219:3000",
    })
  : axios.create({
      //development 환경
      baseURL: "http://localhost:3000",
      // baseURL: "http://192.168.0.20:3000",
    });
