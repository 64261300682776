<template>
  <div class="payment-page" v-if="memberSajuResult">
    <v-row justify="center" align="center" class="mt-4" wrap>
      <v-col cols="12" sm="8" md="6" lg="4">
        <!-- 기본 정보 카드 -->
        <div
          style="color: #616161; font-size: 22px"
          justify="center"
          align="center"
        >
          <strong>프리미엄 구매하기</strong>
        </div>

        <v-card class="pa-4" elevation="0">
          <!-- 큰 제목: 가운데 정렬 -->
          <v-card-title
            class="page-title text-h5 text-primary font-weight-bold text-center"
          >
            {{ fortuneContent.title }}
          </v-card-title>

          <!-- 부제목: 가운데 정렬 -->
          <v-card-subtitle class="content text-subtitle-1 text-left mb-4">
            <div>{{ fortuneContent.subtitle }}</div>
            <div>
              <span
                style="
                  color: gray;
                  padding-right: 5px;
                  text-decoration: line-through;
                "
              >
                {{ fortuneContent.originalPrice.toLocaleString("ko-KR") }}원
              </span>
              <span style="color: black; padding-right: 5px">
                {{
                  fortuneContent.discountPrice.toLocaleString("ko-KR")
                }}원</span
              >
              <span style="color: #ff6b6b; padding-left: 5px">
                {{
                  calculateDiscountRate(
                    fortuneContent.originalPrice,
                    fortuneContent.discountPrice
                  )
                }}% 할인
              </span>
            </div>
          </v-card-subtitle>

          <v-card-text class="text-left">
            <span style="color: #7b61ff; font-size: 20px; font-weight: bold">
              사주 정보
            </span>
            <div v-if="memberSajuResult.member.sexType == 'MALE'">
              남성 ({{ memberSajuResult.member.age }}세)
            </div>
            <div v-else>여성 ({{ memberSajuResult.member.age }}세)</div>
            <div>
              <span v-if="memberSajuResult.member.birthdayType == 'SOLAR'">
                양력
              </span>
              <span v-else>음력 </span>
              {{ memberSajuResult.member.birthday }}
              <span v-if="memberSajuResult.member.time"
                >({{ memberSajuResult.member.time }})</span
              >
            </div>
          </v-card-text>
          <fortune-result-format-view
            :yearSky="memberSajuResult.saju.yearSky"
            :yearGround="memberSajuResult.saju.yearGround"
            :monthSky="memberSajuResult.saju.monthSky"
            :monthGround="memberSajuResult.saju.monthGround"
            :daySky="memberSajuResult.saju.daySky"
            :dayGround="memberSajuResult.saju.dayGround"
            :timeSky="memberSajuResult.saju.timeSky"
            :timeGround="memberSajuResult.saju.timeGround"
            :bigFortuneSky="memberSajuResult.fortune.bigFortune.sky"
            :bigFortuneGround="memberSajuResult.fortune.bigFortune.ground"
            :smallFortuneSky="memberSajuResult.fortune.smallFortune.sky"
            :smallFortuneGround="memberSajuResult.fortune.smallFortune.ground"
          />

          <!-- 이미지 섹션 -->
          <div class="text-center" style="margin-top: 30px">
            <img
              :src="fortuneContent.detailPageImageUrl"
              alt="Main Image"
              width="60%"
            />
          </div>

          <!-- 상품 목차 -->
          <v-card-text
            v-if="fortuneContent.tableOfContents"
            class="description text-left"
          >
            <vue-markdown
              class="markdown-description"
              :key="fortuneContent.tableOfContents"
            >
              {{ fortuneContent.tableOfContents }}
            </vue-markdown>
          </v-card-text>
        </v-card>

        <v-expansion-panels multiple class="v-expansion-panels">
          <!-- 첫 번째 패널 예시 -->
          <v-expansion-panel>
            <v-expansion-panel-header
              expand-icon="mdi-chevron-down"
              collapse-icon="mdi-chevron-up"
            >
              <strong>구매 전 유의사항</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul class="custom-li-spacing">
                <li>개인화 콘텐츠를 생성하는 약 1~2분 소요될 수 있습니다.</li>
                <li>구매하신 콘텐츠는 기한 제한 없이 계속 볼 수 있습니다.</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- 두 번째 패널 예시 -->
          <v-expansion-panel>
            <v-expansion-panel-header
              expand-icon="mdi-chevron-down"
              collapse-icon="mdi-chevron-up"
            >
              <strong>환불 규정</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul class="custom-li-spacing">
                <li>결제 후 전송된 콘텐츠는 환불되지 않습니다</li>
                <li>결제 서비스 이용 중 오류 발생시, 100% 환불 처리됩니다.</li>
                <li>
                  환불 문의는 아래 이메일 주소를 통해 접수해주세요.
                  eightcontent.kakao.com
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- 하단 고정 버튼 -->
    <v-btn
      class="fixed-bottom premium-fortune-btn"
      large
      style="height: 56px"
      @click="handlePremiumFortune"
    >
      <!-- Original and Discount Price Display -->
      <span>
        구매하기
        <span style="color: gray; text-decoration: line-through">
          {{ fortuneContent?.originalPrice?.toLocaleString("ko-KR") }}원
        </span>
        ({{ fortuneContent?.discountPrice?.toLocaleString("ko-KR") }}원)
        <span style="color: #ff6b6b">
          {{
            calculateDiscountRate(
              fortuneContent?.originalPrice,
              fortuneContent?.discountPrice
            )
          }}% 할인
        </span>
      </span>
    </v-btn>

    <!-- 로딩 오버레이 -->
    <v-overlay class="loading-center" v-if="loading" :value="loading" fixed>
      <v-progress-circular
        indeterminate
        size="80"
        width="7"
        color="#7b61ff"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueMarkdown from "vue-markdown";
import FortuneResultFormatView from "@/components/format/FortuneResultFormatView.vue";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "PaymentPage",
  data() {
    return {
      loading: false, // 로딩 상태 추가
      fortuneContentId: null,
      sexType: null,
      calendarType: null,
      birthday: null,
      time: null,
      memberId: null,
      memberType: null,
      orderId: null,
    };
  },
  components: {
    VueMarkdown,
    FortuneResultFormatView,
  },
  methods: {
    async handlePremiumFortune() {
      //uuid 생성
      this.orderId = uuidv4();
      const sessionId = localStorage.getItem("session_id");
      const utmCampaign = localStorage.getItem("utm_campaign");
      //api 호출 - 중복 체크 및 주문번호 저장
      try {
        const result = await this.checkPayment(this);
        console.log(result);
        if (result === 409) {
          alert("이미 구매한 상품입니다. 다시 보기 페이지에서 확인해주세요.");
        } else {
          // 토스페이먼츠 공개 키 설정
          const clientKey = process.env.VUE_APP_TOSS_CLIENT_KEY;
          const tossPayments = window.TossPayments(clientKey);
          tossPayments.requestPayment("카드", {
            amount: this.fortuneContent.discountPrice,
            orderId: this.orderId,
            orderName: this.fortuneContent.title,
            successUrl: `${process.env.VUE_APP_API_URL}/v1/payments/success?fortuneContentId=${this.fortuneContentId}&code=${sessionId}&campaign=${utmCampaign}`,
            failUrl: `${process.env.VUE_APP_API_URL}/v1/payments/fail`,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    calculateDiscountRate(originalPrice, discountPrice) {
      if (originalPrice && discountPrice) {
        const discountRate =
          ((originalPrice - discountPrice) / originalPrice) * 100;
        return Math.round(discountRate);
      }
      return 0;
    },

    ...mapActions([
      "analyzeMemberSaju",
      "analyzePremiumFortune",
      "setAccessToken",
      "checkPayment",
    ]),
  },
  computed: {
    ...mapState(["fortuneContent", "memberSajuResult"]),
  },
  async mounted() {
    this.fortuneContentId = this.$route.query.fortuneContentId;
    this.memberType = this.$route.query.memberType;
    this.memberId = this.$route.query.memberId;
    this.sexType = this.$route.query.sexType;
    this.calendarType = this.$route.query.calendarType;
    this.birthday = this.$route.query.birthday;
    this.time = this.$route.query.time;
    this.accessToken = this.$route.query.code;

    //액세스 토큰이 있는 경우 commit SET_ACCESS_TOKEN
    if (this.accessToken) {
      console.log("액세스 토큰:", this.accessToken);
      await this.setAccessToken(this.accessToken);
    }
    //멤버 사주  분석
    await this.analyzeMemberSaju(this);
  },
};
</script>

<style scoped>
.loading-center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* fixed로 변경 */
  top: 0;
  left: 0;
  width: 100vw; /* 뷰포트 너비 */
  height: 100vh; /* 뷰포트 높이 */
  background: rgba(255, 255, 255, 0.8); /* 반투명 배경 */
  z-index: 1000; /* 다른 요소보다 위에 표시 */
}

.free-fortune-result-page {
  padding-top: 64px;
  padding-bottom: 80px; /* 버튼이 컨텐츠를 가리지 않도록 하단에 추가 여백 */
}

.premium-fortune-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px; /* 버튼 높이 */
  z-index: 9999; /* 패널 및 기타 요소보다 위에 오도록 */
  /* 추가로 필요한 스타일 */
  background-color: #ffd600 !important;
  color: #7b61ff !important;
  font-weight: bold;
  font-size: 15px;
}

.saju-result {
  margin: 10px;
  padding: 10px;
}

.v-expansion-panels {
  margin-bottom: 16px; /* 버튼과 간격 */
  padding: 20px;
}

.custom-li-spacing li {
  /* 줄 높이(line-height) 조정: 1.5 ~ 2 정도가 보통 */
  line-height: 1.8;

  /* 아래 여백도 추가 가능 */
  margin-bottom: 8px;
}
</style>
