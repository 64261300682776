<template>
  <td v-if="sky">
    <div>
      <span
        :style="[{ backgroundColor: sky.fiveCircleColor }, { color: 'black' }]"
        >{{ sky.chinese }}</span
      >
      {{ sky.korean }}
      <div class="ten-star">
        <span v-if="flag == 'daySky'">본원</span
        ><span v-else>{{ sky.tenStar }}</span
        >({{ sky.minusPlus }})
      </div>
    </div>
  </td>
  <td v-else class="block"></td>
</template>

<script>
export default {
  name: "FortuneSky",
  props: {
    sky: {
      type: Object,
      default: null,
    },
    flag: {
      type: String,
    },
  },
};
</script>

<style scoped>
.block {
  background-color: #cfd8dc;
}
.ten-star {
  background-color: #cfd8dc;
  font-size: 13px;
}
</style>
