<template>
  <div class="premium-fortune-content-page" v-if="fortuneContent">
    <v-row justify="center" align="center" class="mt-4" wrap>
      <!-- 모바일에서 전체 너비, 큰 화면에서는 4 열 차지 -->
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="pa-4" elevation="0">
          <!-- 큰 제목: 가운데 정렬 -->
          <v-card-title
            class="page-title text-h5 text-primary font-weight-bold text-center"
          >
            {{ fortuneContent.title }}
          </v-card-title>

          <!-- 부제목: 가운데 정렬 -->
          <v-card-subtitle class="content text-subtitle-1 text-left mb-4">
            <div>{{ fortuneContent.subtitle }}</div>
            <div>
              <span
                style="
                  color: gray;
                  padding-right: 5px;
                  text-decoration: line-through;
                "
              >
                {{ fortuneContent?.originalPrice?.toLocaleString("ko-KR") }}원
              </span>
              <span style="color: black; padding-right: 5px">
                {{
                  fortuneContent?.discountPrice?.toLocaleString("ko-KR")
                }}원</span
              >
              <span style="color: #ff6b6b; padding-left: 5px">
                {{
                  calculateDiscountRate(
                    fortuneContent.originalPrice,
                    fortuneContent.discountPrice
                  )
                }}% 할인
              </span>
            </div>
          </v-card-subtitle>

          <!-- 이미지 섹션 -->
          <div class="text-center">
            <img
              :src="fortuneContent.detailPageImageUrl"
              alt="Main Image"
              width="80%"
            />
          </div>

          <!-- 상품 소개 -->
          <v-card-text
            v-if="fortuneContent.intro"
            class="description text-left"
          >
            <vue-markdown
              class="markdown-description"
              :key="fortuneContent.intro"
            >
              {{ fortuneContent.intro }}
            </vue-markdown>
          </v-card-text>
          <v-divider></v-divider>
          <!-- 상품 목차 -->
          <v-card-text
            v-if="fortuneContent.tableOfContents"
            class="description text-left"
          >
            <vue-markdown
              class="markdown-description"
              :key="fortuneContent.tableOfContents"
            >
              {{ fortuneContent.tableOfContents }}
            </vue-markdown>
          </v-card-text>
        </v-card>
        <v-expansion-panels multiple class="v-expansion-panels">
          <!-- 첫 번째 패널 예시 -->
          <v-expansion-panel>
            <v-expansion-panel-header
              expand-icon="mdi-chevron-down"
              collapse-icon="mdi-chevron-up"
            >
              <strong>구매 전 유의사항</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul class="custom-li-spacing">
                <li>개인화 콘텐츠를 생성하는 약 1~2분 소요될 수 있습니다.</li>
                <li>구매하신 콘텐츠는 기한 제한 없이 계속 볼 수 있습니다.</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- 두 번째 패널 예시 -->
          <v-expansion-panel>
            <v-expansion-panel-header
              expand-icon="mdi-chevron-down"
              collapse-icon="mdi-chevron-up"
            >
              <strong>환불 규정</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul class="custom-li-spacing">
                <li>결제 후 전송된 콘텐츠는 환불되지 않습니다</li>
                <li>결제 서비스 이용 중 오류 발생시, 100% 환불 처리됩니다.</li>
                <li>
                  환불 문의는 아래 이메일 주소를 통해 접수해주세요.
                  eightcontent.kakao.com
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- 하단 고정 버튼 -->
    <v-btn
      class="fixed-bottom premium-fortune-btn"
      style="height: 56px"
      large
      @click="openMemberForm"
    >
      <span>시작하기</span>
    </v-btn>

    <!-- UserForm 다이얼로그 -->
    <MemberForm v-model="dialog" @freeFortune="handlePremiumFortune" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import VueMarkdown from "vue-markdown";
import MemberForm from "./MemberForm.vue";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "PremiumFortuneContentPage",
  data() {
    return {
      dialog: false, // UserForm 다이얼로그 열기 여부
      fortuneContentId: null,
    };
  },
  async created() {
    const id = this.$route.params.id;
    this.fortuneContentId = id;
    await this.getFortuneContent(id);
    this.checkAndCreateSessionId();
  },
  components: {
    VueMarkdown,
    MemberForm,
  },
  methods: {
    checkAndCreateSessionId() {
      try {
        let sessionId = localStorage.getItem("session_id");
        console.log(sessionId);
        // 세션 ID가 없으면 새로 생성
        if (sessionId === null) {
          sessionId = uuidv4();
          localStorage.setItem("session_id", sessionId);
          console.log("New session created:", sessionId);

          // URL 파라미터에서 UTM 정보 가져오기
          const urlParams = new URLSearchParams(window.location.search);
          const utmSource = urlParams.get("source") || "";
          const utmMedia = urlParams.get("media") || "";
          const utmCampaign = urlParams.get("campaign") || "organic";
          localStorage.setItem("utm_campaign", utmCampaign);
          // 레퍼러 정보 가져오기
          const referer = document.referrer || "";
          // 사용자 에이전트 정보 가져오기
          const userAgent = navigator.userAgent;

          // API 호출
          this.sendEvent({
            sessionId: sessionId,
            eventType: "PAGE_VIEW",
            fortuneContentId: this.fortuneContentId,
            utmSource: utmSource,
            utmMedia: utmMedia,
            utmCampaign: utmCampaign,
            referer: referer,
            userAgent: userAgent,
          });
        }
      } catch (e) {
        console.warn("localStorage is not available:", e);
      }
    },

    calculateDiscountRate(originalPrice, discountPrice) {
      if (originalPrice && discountPrice) {
        const discountRate =
          ((originalPrice - discountPrice) / originalPrice) * 100;
        return Math.round(discountRate);
      }
      return 0;
    },
    openMemberForm() {
      this.dialog = true; // 다이얼로그 열기
    },
    async handlePremiumFortune(data) {
      if (!this.isAuthorized) {
        // 쿼리 파라미터로 페이지 이동하기
        if (data.memberType === "existing") {
          this.$router.push({
            name: "AuthPage",
            query: {
              serviceType: "PREMIUM",
              fortuneContentId: this.fortuneContentId,
              memberType: data.memberType,
              memberId: data.memberId,
            },
          });
        } else if (data.memberType === "new") {
          this.$router.push({
            name: "AuthPage",
            query: {
              serviceType: "PREMIUM",
              fortuneContentId: this.fortuneContentId,
              memberType: data.memberType,
              sexType: data.sexType,
              calendarType: data.calendarType,
              birthday: data.birthday,
              time: data.time,
            },
          });
        }
      } else {
        if (data.memberType === "existing") {
          this.$router.push({
            name: "PaymentPage",
            query: {
              fortuneContentId: this.fortuneContentId,
              memberType: data.memberType,
              memberId: data.memberId,
            },
          });
        } else if (data.memberType === "new") {
          this.$router.push({
            name: "PaymentPage",
            query: {
              fortuneContentId: this.fortuneContentId,
              memberType: data.memberType,
              sexType: data.sexType,
              calendarType: data.calendarType,
              birthday: data.birthday,
              time: data.time,
            },
          });
        }
      }
    },
    ...mapActions(["getFortuneContent", "sendEvent"]),
  },
  computed: {
    ...mapState(["fortuneContent"]),
    ...mapGetters(["isAuthorized"]),
  },
};
</script>

<style scoped>
.premium-fortune-content-page {
  padding-top: 34px;
  padding-bottom: 80px; /* 버튼이 컨텐츠를 가리지 않도록 하단에 추가 여백 */
}

.premium-fortune-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px; /* 버튼 높이 */
  z-index: 9999; /* 패널 및 기타 요소보다 위에 오도록 */
  /* 추가로 필요한 스타일 */
  background-color: #ffd600 !important;
  color: #7b61ff !important;
  font-weight: bold;
  font-size: 15px;
}
.page-title {
  font-size: 24px;
  font-weight: bold;

  text-align: center;
}

.content {
  font-size: 18px;
  font-weight: 500;
  color: #333333; /* 부제목 색상 */
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.6;
  opacity: 0.8; /* 약간 투명하게 */
}

::v-deep .markdown-content {
  padding-left: 40px;
}

::v-deep .markdown-content ol {
  padding-left: 40px;
  list-style-position: inside;
}

::v-deep .markdown-content li {
  text-align: left;
  margin-bottom: 10px;
}

::v-deep .markdown-content span {
  color: #7b61ff;
  font-size: 12px;
  font-weight: bold;
}

::v-deep .markdown-description div {
  /* padding-left: 40px; */
  text-align: left;
  font-size: 12px;
}

::v-deep .markdown-description span {
  color: #7b61ff;
  font-size: 15px;
  font-weight: bold;
}

::v-deep .markdown-description h3 {
  margin-bottom: 10px;
}

.v-expansion-panels {
  margin-bottom: 16px; /* 버튼과 간격 */
  padding: 20px;
}

.custom-li-spacing li {
  /* 줄 높이(line-height) 조정: 1.5 ~ 2 정도가 보통 */
  line-height: 1.8;

  /* 아래 여백도 추가 가능 */
  margin-bottom: 8px;
}
</style>
