import { render, staticRenderFns } from "./PremiumFortuneContentPage.vue?vue&type=template&id=ad1acccc&scoped=true"
import script from "./PremiumFortuneContentPage.vue?vue&type=script&lang=js"
export * from "./PremiumFortuneContentPage.vue?vue&type=script&lang=js"
import style0 from "./PremiumFortuneContentPage.vue?vue&type=style&index=0&id=ad1acccc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad1acccc",
  null
  
)

export default component.exports