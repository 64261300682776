import Vue from "vue";
import Router from "vue-router";
import PremiumFortuneResultPage from "@/components/fortune-result/PremiumFortuneResultPage";
import FreeFortuneResultPage from "@/components/fortune-result/FreeFortuneResultPage";
import AuthPage from "@/components/auth/AuthPage";
import FreeFortuneContentPage from "@/components/fortune-content/FreeFortuneContentPage";
import PremiumFortuneContentPage from "@/components/fortune-content/PremiumFortuneContentPage";
import PremiumFortuneAnalysisPage from "@/components/fortune-analysis/PremiumFortuneAnalysisPage";
import PaymentPage from "@/components/payment/PaymentPage";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    //인증 페이지
    {
      path: "/auth",
      component: AuthPage,
      name: "AuthPage",
    },
    //프리미엄 운세 결과 페이지
    {
      path: "/premium-fortune-result/:id",
      component: PremiumFortuneResultPage,
      name: "PremiumFortuneResultPage",
    },
    //무료 운세 결과 페이지
    {
      path: "/free-fortune-result",
      component: FreeFortuneResultPage,
      name: "FreeFortuneResultPage",
    },

    //무료 운세 컨텐츠 페이지
    {
      path: "/free-fortune-content/:id", // 동적 파라미터 id 추가
      component: FreeFortuneContentPage,
      name: "FreeFortuneContentPage",
    },
    //프리미엄 운세 컨텐츠 페이지
    {
      path: "/premium-fortune-content/:id", // 동적 파라미터 id 추가
      component: PremiumFortuneContentPage,
      name: "PremiumFortuneContentPage",
    },
    //프리미엄 운세 분석 페이지
    {
      path: "/premium-fortune-analysis",
      component: PremiumFortuneAnalysisPage,
      name: "PremiumFortuneAnalysisPage",
    },
    //결제 페이지
    {
      path: "/payment",
      component: PaymentPage,
      name: "PaymentPage",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // savedPosition이 있으면 해당 위치로 스크롤 (뒤로가기/앞으로가기 시)
    if (savedPosition) {
      return savedPosition;
    } else {
      // 페이지 이동 시 항상 맨 위로 스크롤
      return { x: 0, y: 0 };
    }
  },
});
