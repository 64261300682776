<template>
  <div>
    <v-dialog v-model="dialogLocal" persistent max-width="500px">
      <v-card class="user-form-card">
        <v-form ref="form">
          <!-- 로그인 상태에 따라 기존 멤버 선택과 새로운 멤버 폼을 조건부로 표시 -->

          <template v-if="this.isAuthorized">
            <v-radio-group
              v-model="memberType"
              row
              class="tab-radio-group ma-2"
            >
              <v-radio
                label="기존 멤버"
                value="existing"
                class="tab-radio"
              ></v-radio>
              <v-radio
                label="신규 멤버"
                value="new"
                class="tab-radio"
              ></v-radio>
            </v-radio-group>

            <!-- 기존 멤버 드롭다운 -->

            <div class="d-flex justify-center align-center">
              <v-select
                class="select-member"
                v-if="memberType === 'existing' && memberList"
                v-model="memberId"
                :items="selectMemberOptions"
                item-text="label"
                item-value="value"
                label="멤버 선택"
                style="width: 80%; max-width: 400px; margin: 20px 20px 0 20px"
              ></v-select>
            </div>

            <!-- 선택된 멤버 상세 정보 표시 -->
            <v-card-text v-if="memberType === 'existing' && selectedMember">
              <v-container fluid class="info-container">
                <v-row>
                  <v-col>
                    <p>
                      <span class="info-label">성별 : </span>
                      <span class="info-content">
                        {{
                          selectedMember.sexType === "MALE" ? "남성" : "여성"
                        }}
                      </span>
                    </p>

                    <p>
                      <span class="info-label">생일 : </span>
                      <span class="info-content">{{
                        selectedMember.birthday
                      }}</span>
                      <span class="info-content">
                        {{
                          selectedMember.calendarType === "SOLAR"
                            ? "양력"
                            : "음력"
                        }}
                      </span>
                    </p>
                    <p>
                      <span class="info-label">시간 : </span>
                      <span class="info-content">{{
                        selectedMember.time
                      }}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </template>

          <!-- 새로운 멤버 폼 양식 (비로그인 상태에서도 항상 표시) -->
          <v-card-text v-if="!isAuthorized || memberType === 'new'">
            <v-container fluid>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="12">
                  <v-radio-group v-model="sexType" row class="ma-2">
                    <span>성별 :</span>
                    <v-radio label="남성" value="MALE"></v-radio>
                    <v-radio label="여성" value="FEMALE"></v-radio>
                  </v-radio-group>

                  <v-radio-group v-model="calendarType" row class="ma-2">
                    <span>타입 :</span>
                    <v-radio label="양력" value="SOLAR"></v-radio>
                    <v-radio label="음력" value="LUNAR"></v-radio>
                  </v-radio-group>

                  <v-text-field
                    label="* 생년월일 (예: 19950318) 필수"
                    required
                    v-model="birthday"
                    class="ma-2"
                    outlined
                    dense
                    :rules="[
                      () => !!birthday || '생년월일 입력은 필수 입니다.',
                      () =>
                        (!!birthday && birthday.length == 8) ||
                        '생년월일은 8 자리 숫자로 입력하세요 (예: 19950318)',
                      (v) =>
                        isValidDate(v) ||
                        '생년월일은 1900년 01월01일 ~ 2100년 12월31일 가능합니다.',
                    ]"
                    type="number"
                  />

                  <div class="birth-time">태어난 시간</div>
                  <v-radio-group v-model="timeOption" row class="ma-2">
                    <v-radio
                      class="radio-time"
                      label="시간 입력"
                      value="KNOWN"
                    ></v-radio>
                    <v-radio
                      class="radio-time"
                      label="시간 모름"
                      value="UNKNOWN"
                    ></v-radio>
                  </v-radio-group>

                  <v-text-field
                    v-if="timeOption === 'KNOWN'"
                    label="(예: 0724) 입력"
                    v-model="time"
                    class="ma-2"
                    outlined
                    dense
                    :rules="[
                      // () => !!time || '내가 태어난 시간을 입력하세요.',
                      () =>
                        !time ||
                        (!!time && time.length == 4) ||
                        '시간은 4 자리 숫자로 입력하세요 (예: 0724)',
                      (v) =>
                        !time ||
                        /(0[0-9]|1[0-9]|2[0-3])(0[0-9]|[1-5][0-9])$/g.test(v) ||
                        '시간은 00~23 시간과 00~59 분의 형식으로 입력하세요',
                    ]"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <!-- 버튼 영역 -->
          <v-row justify="center" align="center" class="mt-4">
            <v-btn
              class="custom-button secondary-button"
              outlined
              @click="closeDialog"
            >
              취소
            </v-btn>
            <v-btn
              class="custom-button primary-button"
              outlined
              @click="onClickFreeFortune"
            >
              다음
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "MemberForm",
  data() {
    return {
      memberType: null,
      birthday: null,
      time: null,
      calendarType: "SOLAR",
      sexType: "FEMALE",
      timeOption: "UNKNOWN",
      memberId: null,
    };
  },
  props: {
    value: Boolean, // 부모 컴포넌트로부터 v-model을 통해 전달받을 값
  },
  watch: {
    timeOption(value) {
      if (value === "UNKNOWN") {
        this.time = null;
      }
    },
  },
  async created() {
    this.isAuthorized ? await this.getMemberList() : null;
    this.isAuthorized ? this.setDefaultMemberId() : null; // 드롭다운 기본값 설정
    this.memberType = this.isAuthorized ? "existing" : "new";
  },
  computed: {
    dialogLocal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    selectMemberOptions() {
      return (this.memberList || []).map((member) => ({
        label: `${member?.birthday} (${
          member?.sexType === "MALE" ? "남성" : "여성"
        })`,
        value: member?.id,
      }));
    },
    selectedMember() {
      // 현재 선택된 memberId에 해당하는 멤버 객체 반환
      return this.memberList
        ? this.memberList.find((member) => member.id === this.memberId)
        : null;
    },
    ...mapState(["memberList"]),
    ...mapGetters(["isAuthorized"]),
  },
  methods: {
    onClickFreeFortune() {
      //유효성 검사
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$emit("freeFortune", this);
    },
    closeDialog() {
      this.$emit("input", false); // 다이얼로그 닫기
    },

    isValidDate(value) {
      if (
        !/^(19[0-9]{2}|20\d{2}|2100)(0[1-9]|1[0-2])(0[0-9]|[1-2][0-9]|3[0-1])$/.test(
          value
        )
      ) {
        return false;
      }

      const year = parseInt(value.substring(0, 4), 10);
      const month = parseInt(value.substring(4, 6), 10) - 1;
      const day = parseInt(value.substring(6, 8), 10);

      const date = new Date(year, month, day);
      return (
        date.getFullYear() === year &&
        date.getMonth() === month &&
        date.getDate() === day
      );
    },
    setDefaultMemberId() {
      const defaultMember = this.memberList.find(
        (member) => member.status === "ON"
      );
      if (defaultMember) {
        this.memberId = defaultMember.id;
      }
    },
    ...mapActions(["getMemberList"]),
  },
};
</script>

<style scoped>
.user-form-card {
  padding: 16px;
}

.radio-time {
  margin-bottom: 20px;
}

.birth-time {
  font-size: 16px;
}

/* 버튼 스타일 커스터마이징 */
.custom-button {
  width: 120px; /* 버튼 최소 너비 설정 */
  margin: 10px; /* 버튼 간격 설정 */
}

/* 텍스트 스타일 */
.info-label {
  font-size: 1rem; /* 라벨 폰트 크기 */
  color: #555555; /* 라벨 색상 */
  font-weight: bold;
  margin-bottom: 4px;
}

.info-content {
  font-size: 1rem; /* 콘텐츠 폰트 크기 */
  color: #333333; /* 콘텐츠 색상 */
  margin-bottom: 12px;
}

/* 전체 컨테이너 패딩 조정 */
.info-container {
  padding: 16px;
  background-color: #f9f9f9; /* 컨테이너 배경색 */
  border-radius: 8px;
}

/* 전체 라디오 그룹 배경과 여백 조정 */
.tab-radio-group {
  display: flex;
  justify-content: space-between; /* 각 탭 간 여백 균일 배분 */
  background-color: #f0f0f0; /* 배경색 */
  border-radius: 8px;
  padding: 8px; /* 라디오 그룹 내부 여백 */
  gap: 8px; /* 각 탭 간 간격 */
}

/* 개별 탭 스타일 */
.tab-radio {
  flex: 1;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

/* 기본 라디오 버튼의 동그라미 숨기기 */
.tab-radio .v-input--selection-controls__input {
  display: none;
}

/* 활성화된 탭 스타일 */
.tab-radio-group .v-item--active .tab-radio {
  background-color: #4caf50; /* 활성 탭 배경색 */
  color: white; /* 활성 탭 글자색 */
}

.select-member {
  margin-top: 30px;
}
</style>
