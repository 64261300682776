<template>
  <div class="premium-fortune-analysis-page">
    <v-row justify="center" align="center" class="mt-4" wrap>
      <v-col cols="12" sm="8" md="6" lg="4"> </v-col>
    </v-row>

    <!-- 로딩 오버레이 -->
    <v-overlay
      class="loading-center"
      v-if="loading"
      :value="loading"
      fixed
      opacity="0.9"
    >
      <div class="overlay-content text-center">
        <p class="overlay-text mb-5">
          프리미엄 운세 분석 중입니다.<br />
          약 1 ~ 2분 정도 소요됩니다.
        </p>
        <v-progress-circular
          indeterminate
          size="70"
          width="6"
          color="#7b61ff"
        ></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PremiumFortuneAnalysisPage",
  data() {
    return {
      loading: false,
      fortuneResultId: null,
    };
  },
  methods: {
    ...mapActions(["analyzePremiumFortune"]),
  },
  async mounted() {
    this.fortuneResultId = this.$route.query.fortuneResultId;
    this.orderId = this.$route.query.orderId;
    this.loading = true; // 로딩 시작
    try {
      // 운세 분석하기
      const res = await this.analyzePremiumFortune(this);
      if (res.data.statusCode === 200) {
        // 운세 결과 페이지로 이동
        this.$router.push({
          name: "PremiumFortuneResultPage",
          params: {
            id: this.fortuneResultId,
          },
        });
      } else {
        alert("프리미엄 운세 분석 권한이 없습니다.");
        //시작페이지
        this.$router.push({ path: "/" });
      }
    } catch (error) {
      console.error("유료 분석 실패:", error);
    } finally {
      this.loading = false; // 로딩 종료
    }
  },
};
</script>

<style scoped>
.loading-center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.overlay-content {
  background: white;
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.2);
}

.overlay-text {
  font-size: 1.1rem;
  color: #555;
}
.premium-fortune-analysis-page {
  padding-top: 64px;
  padding-bottom: 1000px;
}
</style>
